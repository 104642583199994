//overwrite
body{    
    font-family: $mfont;    
    font-size: pxToEm(15);
    line-height: 1.5;
    @include font-color(#707070,300);
    overflow-x: hidden;
    opacity: 0;
    visibility: hidden;
    &.fade-in{
        opacity: 1;
        transition:opacity  1.5s ease;
        visibility: visible;
    }
}
body{    
    &.modal_open main{
        height: calc(100vh - 40px);
        overflow: hidden;
    }
}
main{       
    &.pd0{
        padding-bottom: 0;
    }
    @include media-breakpoint-down(xs){
        .container,.col-12{
            padding-left: 20px;
            padding-right: 20px;
        }
        .row{
            margin-right: -20px;
            margin-left: -20px;
        }
    }
    @include media-breakpoint-down(sm){
        &:not(.indx){
            background:no-repeat url(../images/innerbg_sm.jpg) top right / contain;
        }
        padding-bottom: 80px;
        // padding-top: 60px;
    } 
    @include media-breakpoint-up(md){  
        &:not(.indx){
            background:no-repeat url(../images/innerbg.jpg) top right / 2000px;
        }
        padding-bottom: 120px;
        // padding-top: 83px;
        .container{
            max-width: 1200px;
        }
    }
}
.btn_box{
    min-width: 110px;
    background-color: #707070;
    display: inline-block;
    text-align: center;
    @include font-color(white);
    border:1px solid #707070;
    &:not(.joinbtn){
        height: 40px;
        line-height: 40px;
        letter-spacing: 0.75px;
    }
    @include media-breakpoint-down(xs){
        &:hover{
            color:white;
        }
    }
    @include media-breakpoint-up(sm){
        &:hover{
            background-color: white;
            color: #707070;
        }
    }
}
.btn_line{
    @include font-color(#707070);
    font-family: $fen;
    letter-spacing: 0.78px;
    @include media-breakpoint-down(md){
        font-size: pxToEm(14);
    }
    @include media-breakpoint-up(sm){
        font-size: pxToEm(16);
    }
    &:hover{
        color:#707070;
    }
    i{
        display: inline-block;
        height: 1px;
        background-color: #a0a0a0;
        position: relative;
        vertical-align: 1px;
        transition: transform 0.6s;        
        @include media-breakpoint-down(xs){
            width:40px;
        }
        @include media-breakpoint-up(sm){
            width:80px;
        }
    }
    &.mr{
        i{
            margin-left: 10px;
        }
        i:after{
            content:' ';
            display: inline-block;
            width:8px;
            height: 1px;
            transform: rotate(45deg);
            transform-origin:right;
            background-color: #a0a0a0;
            @include position(absolute,$top:0,$right:0);
        }
    }
    &.ml{
        i{
            margin-right: 10px;
        }
        i:before{
            content:' ';
            display: inline-block;
            width:8px;
            height: 1px;
            transform: rotate(-45deg);
            transform-origin:left;
            background-color: #a0a0a0;
            @include position(absolute,$top:0,$left:0);
        }
        @include media-breakpoint-up(md){
            &:hover{
                i{
                    transform: translateX(-15px) ;
                }
            }
        }
    }
}
//hover box
.hoverbox{    
    .bgcover{
        transform: scale(1);
        transition: transform 0.6s;
    }
    @include media-breakpoint-up(md){
        &:hover{
            .bgcover{
                transform: scale(1.1);
            }
            .btn_line.mr i{
                transform: translateX(15px) ;
            }
            .btn_line.ml i{
                transform: translateX(-15px) ;
            }
        }
    }
}
.pgkv{
    line-height: 1;
    display: flex;
    text-align: center;
    @include media-breakpoint-down(sm){
        height: 220px;
        align-items: center;
    }
    @include media-breakpoint-up(md){
        height: 330px;
    }
    > div{
        flex:0 0 100%;
        @include media-breakpoint-down(sm){
            padding-top:75px;
        }
        @include media-breakpoint-up(md){
            padding-top: 185px;
        }
    }
    .title-en{
        font-family: $fen;
        @include font-color(black,700);
        @include media-breakpoint-down(xs){
            font-size: pxToEm(20);
        }
        @include media-breakpoint-up(sm){
            font-size: pxToEm(32);
        }
    }
    .title-ch{
        margin-top: 12px;        
        @include font-color(black);
        @include media-breakpoint-down(xs){
            font-size: pxToEm(16);
            margin-top: 6px; 
        }
        @include media-breakpoint-up(sm){            
            font-size: pxToEm(24);
            margin-top: 12px; 
        }
    }
}
div.revealOnScroll{
    opacity: 0;
    transform: translateY(60px);
    transition: opacity 1.5s ,transform 1.5s;
}
div.revealOnScroll.animated{
    opacity: 1;
    transform: translateY(0px);
}
