//單頁的編輯器內容
.editor_Box{
    background-color: white;
    box-shadow: 0px 0px 15px -3px #b3b3b3;
    @include media-breakpoint-down(xs){
        padding:20px 24px;
    }
    @include media-breakpoint-up(sm){
        padding:45px 32px;
    }
    &.mb50{
        @include media-breakpoint-down(xs){
            margin-bottom: 40px;
        }
        @include media-breakpoint-up(sm){
            margin-bottom: 50px;
        }
    }
}
.recruit{
    .btn_box{
        letter-spacing: 1.2px;
        font-weight: 700;
        @include media-breakpoint-down(xs){
            font-size: pxToEm(14);
            width: 130px;
            height: 40px;
            line-height: 39px;
        }
        @include media-breakpoint-up(sm){
            font-size: pxToEm(24);
            width: 160px;
            height: 48px;
            line-height: 47px;
        }
    }
}