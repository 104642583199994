header{
    @include position(fixed,$top:0,$left:0);
    z-index: 999;
    width:100%;   
    @include media-breakpoint-up(sm){        
        padding:15px 0px;
    }
    .title-bar{        
        @include position(absolute,$top:0,$left:0);
        background-color: rgba(white,0.8);
        box-shadow: 0px 5px 4.6px 0.5px rgba(0, 0, 0, 0.07);
        width:100%;
        transition: background 0.6s;
        @include media-breakpoint-down(sm){
            height: 55px; 
            z-index: 100;
        }
        @include media-breakpoint-up(md){
            height: 65px; 
        }
    }
    //mmenu
    ul.mmenu{   
        @include media-breakpoint-down(sm){
            width:100%;
            @include position(absolute,$top:50%,$left:0);
            transform: translateY(-50%);
        }
		@include media-breakpoint-up(md){
            display: flex;
            justify-content:flex-end;
            align-items: center;
        }  
        @include media-breakpoint-only(md){
            padding-right: 20px;
        }
        @include media-breakpoint-up(lg){
            padding-right: 60px;
        }
		> li{
            a{
                position: relative;
                font-size: pxToEm(16);
                @include font-color(black,300);
                letter-spacing: 0.8;
                line-height: 36px;  
                display: block;
                &:after{
                    content: ' ';
                    display: block;
                    width:100%;
                    height: 1px;
                    background-color: black;
                    margin-top: -3px;
                    opacity: 0;
                    transition: opacity 0.6s;
                }
            }
            @include media-breakpoint-down(sm){
                text-align: center;
                display: block;
                a{
                    padding: 15px 0;
                    margin:10px 0;
                }
            }
            @include media-breakpoint-up(md){
                margin-left:35px;
                a:hover:after,
                &.active a:after{
                    opacity: 1;
                }
            }    
        }
	}//end mmenu    
    .menuicon{
        position: absolute;
        @include position(absolute, $right:16px, $top:50%);
        transform: translateY(-50%);
		z-index: 10;       
        .mpsrel{
            width:37px;
            height:37px;
        }
        .bar,.bar:after,.bar:before{
            transition: all .25s;
            content: "";
            position: absolute;
            left: 50%;	  
            top:0;
            height: 2px;
            width: 26px;
            background:black;
            transform: translateX(-50%);
        }
        .bar{
            margin-top: 18px;
        }
        .bar:after{top: 10px;}
        .bar:before{top: -10px;}	
		
		@include media-breakpoint-up(md){
			display: none;
		}
    }
    //mobile meun open
    &.menu-expanded{
        .title-bar{
            background-color: white;
        }
        .menuicon .bar{
            background:rgba(black,0);
            &:before{
                top:0px;
                transform: translateX(-50%) rotate(-45deg);
                background-color: black;
    
            }
            &:after{
                top:0px;
                transform: translateX(-50%) rotate(45deg);
                background-color: black;
    
            }
        }
    }
    .logo{
        transform: translateY(-50%);
        @include media-breakpoint-down(md){
            @include position(absolute,$top:50%,$left:20px);
            width:70px;
        }
        @include media-breakpoint-up(lg){
            @include position(absolute,$top:50%,$left:60px);
            width:80px;
        }
    }
    @include media-breakpoint-down(sm){        
        .navBox{
            display: none;
            width:100%;           
            height:calc(100vh - 55px);
            position: fixed;
			top:55px;
			left:0;
            z-index: 99;
            background-color: rgba(white,1);  
			-webkit-overflow-scrolling: touch;
        }
    }
}
