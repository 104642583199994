.about{
    .bgbtm{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        @include position(absolute,$bottom:0,$left:50%);
        transform: translateX(-50%);
        z-index: -1;
        background:no-repeat url(../images/a_visionbg.png) right calc(100% - 40px);
        @include media-breakpoint-down(md){
            background-size: 600px;
            background-position: 70% 98%;
        }
    }
    .mtitle{
        @include font-color(black,700);
        line-height: 1;
        @include media-breakpoint-down(xs){
            font-size: pxToEm(16);
            margin-bottom: 16px;
        }
        @include media-breakpoint-up(sm){
            font-size: pxToEm(24);
            margin-bottom: 45px;
        }
    }
    .mtxt{
        @include media-breakpoint-down(xs){
            line-height: 1.5;
            font-size: pxToEm(14);
        }
        @include media-breakpoint-up(sm){
            line-height: 1.87;
        }
    }
    .slogan{
        line-height: 1.4;
        letter-spacing: 1.6px;
        @include font-color(black);
        position: relative;
        @include media-breakpoint-down(xs){
            font-size: pxToEm(18);
            margin-bottom: 10px;
        }
        @include media-breakpoint-up(sm){
            font-size: pxToEm(32);
            margin-bottom: 30px;
        }
    } 
    .sm-mb{
        @include media-breakpoint-down(sm){
            margin-bottom: 40px;
        }
        @include media-breakpoint-only(md){
            margin-bottom: 70px;
        }
    }
    //3大原則
    .policybox{
        position: relative;
        @include media-breakpoint-down(sm){
            margin:50px 0;
            padding:50px 0;
            background-color: #ebebeb;
        }
        @include media-breakpoint-up(md){
            margin:75px 0 60px;
            padding:64px 0 40px;
            @include gradient(left,(#ebebeb,#fff));
        }
        &:after{
            content:' ';
            display: block;
            width:100%;
            height: 100%;
            @include position(absolute,$right:0,$top:0);
            @include media-breakpoint-down(md){
                background:no-repeat url(../images/a_linebg.png) 100% -23% / 65%;
            }
            @include media-breakpoint-only(md){
                background-position: top right;
                background-size: contain;
            }
            @include media-breakpoint-up(lg){
                background:no-repeat url(../images/a_linebg.png) center right / 360px;
            }
        }
        .col-md-10{
            position: relative;
            z-index: 10;
            
            @include media-breakpoint-up(md){
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }
            @include media-breakpoint-down(md){
                justify-content: center;
            }
            @include media-breakpoint-up(lg){
                justify-content: flex-start;
            }
        }
        .flex-center{
            position: relative;
            @include media-breakpoint-up(md){         
                height: 250px;  
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                text-align: center;   
            }
        }
        .titlebox{          
            @include media-breakpoint-down(sm){
                width: 120px;
                margin:0 auto 50px;
                text-align: center;
                border-top:4px double #a0a0a0;
                border-bottom:4px double #a0a0a0; 
                padding:35px 0;
            }
            @include media-breakpoint-up(md){                
                border-top:5px double #a0a0a0;
                border-bottom:5px double #a0a0a0; 
            }
            @include media-breakpoint-only(md){
                width:185px;
                flex:0 0 185px;
                margin-bottom: 120px;
                + .mbk{
                    flex:0 0 100%;
                    
                }
            }  
            @include media-breakpoint-up(lg){   
                width:20%;
                flex:0 0 20%;
                margin-right: 50px;
                + .mbk{
                    display: none;
                }
            }
            .fs32{
                line-height: 1;
                @include font-color(black,700);
                @include media-breakpoint-down(xs){
                    font-size: pxToEm(20);
                }
                @include media-breakpoint-up(sm){
                    font-size: pxToEm(32);
                }
            }
        }
        .pol{
            box-shadow: 3.1px 3.9px 4.6px 0.5px rgba(0, 0, 0, 0.07);
            background-color: #ffffff;
            @include font-color(black,700);
            line-height: 1;
            img{
                @include media-breakpoint-up(md){
                    display: block;
                    margin:0 auto 16px;
                }
            }
            @include media-breakpoint-down(sm){ 
                width:90%;
                padding:36px 0;
                font-size: pxToEm(16);
                > div:nth-child(1){               
                    display: flex;
                    width:100%;
                    height: 100%;
                    align-items: center;
                }
                span{
                    flex:0 0 50%;
                    width:50%;
                    text-align: center;
                }
                &.mrl{
                    margin:70px 0;
                }
            }
            @include media-breakpoint-up(md){
                font-size: pxToEm(20);
            }
            @include media-breakpoint-only(md){
                width:30%;
                flex:0 0 30%;
                &.mrl{
                    margin:0 5%;
                } 
            }
            @include media-breakpoint-up(lg){
                width:20%;
                flex:0 0 20%;
                &.mrl{
                    margin:0 50px;
                } 
            }
        }
        .num{
            font-family: $fen;
            line-height: 1;
            font-size: pxToEm(100);
            @include font-color(#bae4dc);
            @include position(absolute,$right:-0.5em,$top:-0.5em);
            z-index: 10;
            @include media-breakpoint-down(xs){
                letter-spacing: -0.0em;
                right: -50px;
            }
        }
    }
    //5大意識
    .awar{
        .slogan{
            &:before{
                content:"“ ";
                @include position(absolute,$left:-0.8em,$top:-3px);
            }
        }        
        .mh{
            width:100%;
            @include media-breakpoint-down(xs){
                height: 30px;
            }
            @include media-breakpoint-up(sm){
                height: 60px;
            }
        }
        .pd45{
            @include media-breakpoint-down(xs){
                padding-left: 35px;
            }
        }
    }
    //vision
    .vision{
        @include media-breakpoint-down(xs){
            padding-bottom: 220px;
            margin-top: 60px;
        }
        @include media-breakpoint-up(sm){
            padding-bottom: 120px;
            margin-top: 120px;
        }
        @include media-breakpoint-only(md){
            padding-bottom: 320px;
        }
    }
    //animation aos
    @include media-breakpoint-up(md){
        .revealOnScroll:nth-child(2){
            transition-delay: 0.2s;
        }
        .revealOnScroll:nth-child(3){
            transition-delay: 0.4s;
        }
        .revealOnScroll:nth-child(4){
            transition-delay: 0.6s;
        }
        .vision .revealOnScroll{
            transition-delay: 0.2s;
        }
    }
}