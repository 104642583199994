@import url('https://fonts.googleapis.com/css?family=Noto+Sans+TC:300,400,500,700|Nunito+Sans:400,700&display=swap');
$mfont:'Nunito Sans','Noto Sans TC','Microsoft JhengHei', "微軟正黑體", sans-serif;
$fen:'Nunito Sans','Microsoft JhengHei', "微軟正黑體", sans-serif;


//-------------------------文字顏色-------------------------//
$pink:#f8756b;
$brwon:#b58a4c;
$blue:#00b4cd;
$orange:#ff6e19;
$yel:#fff100;
$green:#2d4e37;
$gray:#eee;
$fontcolor:#413523;
$red:#ae0000;
$menu:#ff6e19;
$acolor:#104179;


$maxConentW:1200px;
$maxConentPding:15px;
