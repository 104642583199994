.brand{
    //品牌
    
    .title-share h1,
    .mlist .mtitle{
        @include font-color(black,700);
        display: inline;            
        line-height: 1.8;
        border-bottom:2px solid black;
        @include media-breakpoint-down(xs){    
            font-size: pxToEm(16); 
        }
        @include media-breakpoint-up(sm){   
            font-size: pxToEm(24);        
        }
    }
    .title-share{
        position: relative;
        @include media-breakpoint-down(xs){
            margin-bottom: 20px; 
            padding-right: 75px;
        } 
        @include media-breakpoint-up(sm){
            margin-bottom: 48px;   
            padding-right: 95px;
        }
        .share{
            @include position(absolute,$top:0.5em,$right:0);
            a{
                display: inline-block;
                opacity: 1;
                margin-left: 8px;
                @include media-breakpoint-down(xs){
                    width:25px;
                }
                @include media-breakpoint-up(sm){
                    width:35px;
                }
            }
            a.hide{
                display: none;
            }
            @include media-breakpoint-up(md){
                a:hover{
                    opacity: 0.5;
                }
            }
        }
    }
    .mlist{
        @include media-breakpoint-up(md){
            margin:0 -8px;
        }
        > div{
            @include media-breakpoint-down(sm){
                margin-bottom: 70px;
            }
            @include media-breakpoint-up(md){
                margin-bottom: 90px;
                display: flex;
                &:nth-child(odd){
                    .textbox{
                        padding-left: 35px;
                    }
                }
                &:nth-child(even){
                    .imgbox{
                        order:2;
                    }
                    .textbox{
                        order: 1;
                    }
                }
            }
        }
        .imgbox,.textbox{
            // overflow: hidden;
            @include media-breakpoint-up(md){
                flex:0 0 50%;
                width:50%;
                padding:0 8px;
            }
        }
        .imgbox{
            //animation aos
            &.revealOnScroll{
                @include media-breakpoint-up(md){
                    transition: opacity 1.5s ,transform 0.8s;
                }
            }
            a{
                box-shadow: 0px 0px 15px -3px #b3b3b3;
                display: block;
                overflow: hidden;
            }
            @include media-breakpoint-down(sm){
                width:100%;
                margin-bottom: 12px;
            }
            .bgcover{
                padding-bottom: 50%;
                background-image: url(../images/brand_default.png);
            }
        }        
        .textbox{
            //animation aos
            &.revealOnScroll{
                @include media-breakpoint-up(md){
                    transition-delay: 0.3s;
                }
            }
            .mpsrel{
                height: 100%;
                padding-top: 1px;
            }        
            .title-mt{
                overflow: hidden;
                @include media-breakpoint-down(xs){
                    height: 30px;
                }
                @include media-breakpoint-up(md){
                    margin-top: -0.9em;
                    height: 44px;
                }
            }
            .mdes{
                overflow: hidden;
                max-height: 4.5em;
                @include media-breakpoint-down(sm){
                    margin-top: 16px;
                    margin-bottom: 30px;
                }
                @include media-breakpoint-up(md){
                    margin-top: 36px;
                    max-width: 380px;
                }
            }
            .btn_line{
                @include media-breakpoint-up(md){                
                    @include position(absolute,$left:0,$bottom:-4px);
                }
            }
        }
    }
}