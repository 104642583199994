footer{    
    min-height: 50px;
    width:100%;
    position: relative;
    background-color: #aaaaaa;
    font-size: pxToEm(14);
    @include font-color(white,300);
    letter-spacing: 0.7;
    line-height: 1.5;
    @include media-breakpoint-down(xs){        
        font-size: pxToEm(12);
    }
    @include media-breakpoint-down(sm){
        padding:16px 20px 4px 100px;
    }
    @include media-breakpoint-up(md){
        padding:16px 60px 4px 200px;
    }
    @include media-breakpoint-only(md){
        padding:16px 20px 4px 160px;
    }
    a[href^=tel],a[href^=mailto]{
        color:white;
        &:hover{
            color:inherit;
        }
    }
    ul{
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        @include media-breakpoint-only(sm){
            width:80%;
        }
        @include media-breakpoint-up(lg){
            justify-content: space-between;
        }
    }
    li.info{
        display: inline-flex;
        flex-wrap: wrap;
        @include media-breakpoint-down(sm){
            a:nth-child(1){
                margin-right: 30px;
            }
        }
        @include media-breakpoint-up(md){
            a{
                margin-right: 30px;
            }
            span{
                margin-right: 70px;
            }
        }
    }
    .logo,
    .copyright,
    li.info a,
    li.info span{
        margin-bottom: 8px;
    }
    .logo{
        transform: translateY(-50%);
        @include media-breakpoint-down(sm){
            width:60px;
            @include position(absolute,$left:20px,$top:50%);
        }
        @include media-breakpoint-up(md){
            width:80px;
            @include position(absolute,$left:60px,$top:50%);
        }
        @include media-breakpoint-only(md){
            left:20px;
        }
    }
    .mtel{
        background: url(../images/icon/f_tel.png) no-repeat 1px 3px / 15px;
        padding-left: 25px;
    }
    .mmail{
        background: url(../images/icon/f_mail.png) no-repeat 2px 5px / 15px;
        padding-left: 25px;
    }
    .maddr{
        padding-left: 25px;
        @include media-breakpoint-down(xs){
            background: url(../images/icon/f_addr.png) no-repeat 3px 1px / 10px;
        }
        @include media-breakpoint-up(sm){
            background: url(../images/icon/f_addr.png) no-repeat 5px 3px / 10px;
        }
    }
}