/*----------------------通用設定------------------------*/
//清除float浮動
%cf,main[role^=main]  {
    zoom: 1;
    &:before,
    &:after {
        content: "";
        display: table;
        height: 0;
        overflow: hidden;
    }
    &:after {
        clear: both;
    }
}
.clearfix {
  @include clearfix();
}

//selection
p,h1,h2,h3,h4,b,strong,span,li,div,a,img{
    &::selection{
          color:#fff;
          background:#000;
        }
}
//a tag
a{outline: none;}
a,a:hover {
  text-decoration: none;
  transition: 0.4s;
  color:initial;
}
*:focus {
    outline: none;
}
img {
    vertical-align: middle;
    max-width: 100%;
}
ul{
  list-style-type: none;
}
//border-box
*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

/*----------------------編輯器------------------------*/
.editor_inner {
  font-size: pxToEm(15);
  @include font-color(#707070,300);  
  letter-spacing: 0.75px;
  @include media-breakpoint-up(sm){
    line-height: 1.5;
  }
  @include media-breakpoint-up(sm){
    line-height: 1.87;
  }
  ul,ol {
      padding-left: 40px;
  }
  img {
      max-width: 100%;
      height: auto !important;
  }
  iframe {
      max-width: 100%;
      @media screen and (max-width: 767px) {
          height: auto !important;
      }
  }
  h1,h2,h3,h4,h5,h6 {
      margin: inherit;
  }
}
/*----------------------頁籤------------------------*/
.page{
    margin:0px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-down(xs){      
      // justify-content: space-around;
    }
    dt{    
      margin-top: -12px;
      i{
        display: inline-block;
        height: 1px;
        background-color: #a0a0a0;
        position: relative;
        transition: transform 0.6s;     
        width:25px;
      }
      &.rtbn{
          i:after{
              content:' ';
              display: inline-block;
              width:10px;
              height: 1px;
              transform: rotate(45deg);
              transform-origin:right;
              background-color: #a0a0a0;
              @include position(absolute,$top:0,$right:0);
          }
      }
      &.ltbn{
          i:before{
              content:' ';
              display: inline-block;
              width:10px;
              height: 1px;
              transform: rotate(-45deg);
              transform-origin:left;
              background-color: #a0a0a0;
              @include position(absolute,$top:0,$left:0);
          }
      }
      @include media-breakpoint-up(md){
        &.rtbn a:not(.nopage):hover i{
            transform: translateX(15px) ;
        }
        &.ltbn a:not(.nopage):hover i{
            transform: translateX(-15px) ;
        }
      }
      //沒有上下則
      .nopage{
        z-index: -1;
        opacity: 0.5;
        cursor: default;
      }
    }
    dd{
      font-family: $fen;
      position: relative;
      &:nth-child(2){
        padding-left: 15px;
      }
      &:nth-last-child(2){
        padding-right: 15px;
      }
      &:after{
        content:' ';
        display: block;
        width:100%;
        height: 1px;
        background-color: #a0a0a0;
        @include position(absolute,$bottom:0,$left:0);
        z-index: -1;
      }
      a{
        display: block;
        color:#a0a0a0;
        position: relative;
        i{
          border-bottom:2px solid rgba(0,0,0,0);
          line-height: 1.5;
          display: block;
          padding:0 2px 6px;
          transition: border 0.6s;
        }
        @include media-breakpoint-down(sm){
          padding:0 10px;
          margin:0 5px;
          i{
            font-size: pxToEm(12);
          }
        }
        @include media-breakpoint-up(md){
          padding:0 10px;
          margin:0 10px;
          i{
            font-size: pxToEm(15);;
          }
          &:hover{
            color:black;
            i{
              border-color: black;
            }
          }  
        }
      }
      a.active{
        color:black;
        i{
          border-color: black;
        }
      }
    }
}
/*----------------------go top------------------------*/
#topBtn{
  text-align: center;
  display:block;
  z-index:1;
  opacity: 0;
  visibility: hidden;
  width:56px;
  height: 60px;  
  @include font-color(#707070);
  letter-spacing: 0.75;
  line-height: 1;
  font-size: pxToEm(15);
  div{
    margin-top: 48px;
  }
  .nohover{
    @include position(absolute,$top:0,$left:0);
    opacity: 1;
  }
  .mhover{
    @include position(absolute,$top:0,$left:0);
    opacity: 0;
  }
  &.open{
    opacity: 1;
    visibility: visible;
    z-index:100;
  }
  &.change{
    position: absolute;
  }
  @include media-breakpoint-down(xs){
    @include position(fixed,$bottom:30px,$right:10px);
    transform-origin: right bottom;
    transform: scale(0.75);
  }
  @include media-breakpoint-up(sm){
    @include position(fixed,$bottom:40px,$right:30px);
  }
  @include media-breakpoint-up(lg){
    &:hover{
      .mhover{
        opacity: 1;
      }
      .nohover{
        opacity: 0;
      }
    }
  } 
}
// custom
.text-center{text-align: center;}
.text-right{text-align:right;}
.text-justify{text-align: justify;}
.mpsrel{position: relative;}
.hide{display:none;}
.bgcover{
  width:100%;
  height:0;
  background: center / cover;
}
.rowbox{
  margin:0 -15px 0 -15px;
  position: relative;
  max-width:$maxConentW;
  padding:0 $maxConentPding;
}

.row.col-md-center{
  justify-content: center;
}
@include media-breakpoint-down(md){
  &.show-lg{
    display: none;
  }
  .bgcover[data-src-lg]{
    content: attr(data-src-lg, url);
  }
}
@include media-breakpoint-up(lg){
  &.show-sm{
    display: none;
  }
  .bgcover[data-src-sm]{
    content: attr(data-src-sm, url);
  }
}