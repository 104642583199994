//共同樣式
.indx{
    overflow-x: hidden;
    .mtitle{
        @include font-color(black,700);
        line-height: 1;
        @include media-breakpoint-down(xs){
            font-size: pxToEm(16);
            margin-bottom: 16px;
        }
        @include media-breakpoint-up(sm){
            font-size: pxToEm(24);
            margin-bottom: 40px;
        }
    }
    .mtxt{
        @include media-breakpoint-down(xs){
            line-height: 1.5;
            font-size: pxToEm(14);
        }
        @include media-breakpoint-up(sm){
            line-height: 1.87;
        }
    }
    .slogan{
        line-height: 1.4;
        letter-spacing: 1.6px;
        @include font-color(black);
        position: relative;
        @include media-breakpoint-down(xs){
            font-size: pxToEm(18);
            margin-bottom: 10px;
        }
        @include media-breakpoint-up(sm){
            font-size: pxToEm(32);
            margin-bottom: 30px;
        }
    }
    .bgtxt{
        line-height: 1;
        font-family: $fen;
        @include font-color(#f6f6f6,700);
        opacity: 0;
        z-index: -1;
        @include media-breakpoint-down(sm){
            font-size: pxToEm(50);
        }
        @include media-breakpoint-up(md){
            font-size: pxToEm(136);
        }        
        &.revealOnScroll{
            transform: translateY(0);
            transition: opacity 1s;
            transition-delay: 0.2s;
        }
        &.revealOnScroll.animated{
            opacity: 0.8;
        }
    }
    
    .bgmap{
        z-index: -1;
        @include media-breakpoint-down(md){
            @include position(absolute,$bottom:0%,$left:-17%);
            width:115%;
            &.show-l{
                display: none;
            }
            + .bgimgH{
                width:100%;
                padding-bottom: 55%;
            }
        }
        @include media-breakpoint-up(lg){
            @include position(absolute,$top:5%,$right:5%);
            width:58%;
            &.show-m{
                display: none;
            }
        }
    }
    .circlebox{
        @include media-breakpoint-down(xs){
            @include position(absolute,$top:40%,$right:12.5%);
        }
        @include media-breakpoint-up(sm){
            @include position(absolute,$top:39%,$right:12%);
        }
        span{
            display: block;
            @include border();
            border:1px solid rgba(#b2b2b2,0.5);
            animation: pulse 3s infinite ease-out;
            @include position(absolute,$top:0%,$right:0%);
            opacity: 0;
            @include media-breakpoint-down(xs){
                width: 30px;
                height: 30px;
                border-color: rgba(#b2b2b2,0.2);
            }
            @include media-breakpoint-up(sm){
                width: 50px;
                height: 50px;
            }
        }
        span:nth-child(1){
            animation-delay: 0s;
        }
        span:nth-child(2){
            animation-delay: 0.5s;
        }
        span:nth-child(3){
            animation-delay: 1.5s;
        }
        span:nth-child(4){
            animation-delay: 2s;
        }
        span:nth-child(5){
            animation-delay: 2.5s;
        }
    }
}
.indx-banner{
    .slider-banner,.bgcover{
        @include media-breakpoint-up(md){
            width:100%;
            height: 100vh;
        }
        @include media-breakpoint-down(sm){
            padding-bottom: 97%;
            height: 0;
        }
    }
    .slick-dots{
        margin: 0;
        @include media-breakpoint-down(sm){
            @include position(absolute,$right:20px,$bottom:10px);
        }
        @include media-breakpoint-up(md){
            @include position(absolute,$right:60px,$bottom:30px);
        }
    }
    .slick-txt{
        z-index: 10;        
        width:80%;
        @include media-breakpoint-down(sm){
            @include position(absolute,$left:20px,$top:30%);
        }
        @include media-breakpoint-up(md){
            @include position(absolute,$left:160px,$bottom:180px);
            max-width: 600px;
        }
        h2{
            line-height: 1.45;
            font-size: pxToEm(42);
            @include font-color(black,700);
            font-family: 'Merriweather Sans', sans-serif;
            margin-bottom: 10px;
            @include media-breakpoint-down(xs){
                font-size: pxToEm(20);
            }
            @include media-breakpoint-up(sm){
                font-size: pxToEm(42);
            }
        }
        h2 + div{
            line-height: 1.33;
            @include font-color(black);
            @include media-breakpoint-down(xs){
                font-size: pxToEm(16);
            }
            @include media-breakpoint-up(sm){                
                font-size: pxToEm(27);
                letter-spacing: 1.9px;
            }
        }
    }
    .linebox{
        overflow: hidden;
        @include position(absolute,$left:60px,$bottom:30px);
        width:25px;
        height: 180px;
        @include media-breakpoint-down(sm){
            display: none;
        }
        .scrline{
            width:1px;
            height: 180px;
            background-color: black;
            @include position(absolute,$left:0,$bottom:0);
        }
        .mline{
            width:2px;
            height: 60px;
            background-color: black;
            @include position(absolute,$top:0,$left:0);
            animation: lineplus 3s infinite ease-out;
        }
        span{
            @include font-color(black);
            font-size: pxToEm(12);
            transform: rotate(90deg);
            transform-origin: left bottom;
            @include position(absolute,$bottom:31px,$left:3px);
        }
    }
}
.indx .indx-brand{
    position: relative;
    @include media-breakpoint-down(xs){
        margin:40px 0 60px;
    }
    @include media-breakpoint-up(sm){
        margin:80px 0 120px;
    }
    //overwrite
    .slick-list{
        @include media-breakpoint-down(md){
            padding-left: 10px;
        }
        @include media-breakpoint-up(lg){
            padding-left: calc(50vw - 15px);
        }
    }
    .slick-slide{
        @include media-breakpoint-down(md){
            padding:10px;
        }
        @include media-breakpoint-up(lg){
            padding:15px;
        }
    }
    .btn-slick-prev,.btn-slick-next{
        width:9px;
        cursor: pointer;
        z-index: 10;
    }
    .btn-slick-prev{
        @include media-breakpoint-down(xs){
            @include position(absolute,$right:80px,$top:0);
        }
        @include media-breakpoint-up(sm){            
            @include position(absolute,$right:100px,$top:0);
        }
    }
    .btn-slick-next{
        @include media-breakpoint-down(xs){
            @include position(absolute,$right:20px,$top:0);
        }
        @include media-breakpoint-up(sm){            
            @include position(absolute,$right:60px,$top:0);
        }
    }
    &.nbtn .btn_separate{
        display: none;
    } 
    &.nbtn_desk{        
        @media (min-width:1500px){
            .btn_separate,.btn-slick-prev,.btn-slick-next{
                display: none;
            }
        }
    } 
    .btn_separate{
        width:1px;
        height: 12px;
        background-color: #a0a0a0;       
        @include media-breakpoint-down(xs){
            @include position(absolute,$right:54px,$top:6px);
        }
        @include media-breakpoint-up(sm){            
            @include position(absolute,$right:84px,$top:6px);
        }
        
    }
    .slider-brand{
        @include media-breakpoint-down(xs){
            padding-top: 30px;
        }
        @include media-breakpoint-up(sm){            
            padding-top: 40px;
        }
    }
    .hoverbox{
        display: block;
        overflow: hidden;
        @include media-breakpoint-down(xs){
            width:220px;
        }
        @include media-breakpoint-up(sm){            
            width:350px;
            &:hover{
                .bgcover{
                    transform: scale(1);
                    opacity: 1;
                }
            }
        }
    }
    .mdeafault{
        width:50vw;
        height: 1px;
        background-color: yellowgreen;
    }
    .bgcover{
        padding-bottom: 48%;
        opacity: 1;
        .bgcover{
            opacity: 0;
            transition: opacity 0.6s;
        }
    }
    h4{
        line-height: 1.5;
        @include font-color(black);
        font-size: pxToEm(18);
        margin:20px 0 16px;
        a{
            display: inline;
            color:black;
        }
    }
}
.indx .indx-about{
    .slogan + div{
        line-height: 1.5;
        letter-spacing: 1.1px;
        @include font-color(black);
        font-family: 'Noto Sans JP', sans-serif;
        @include media-breakpoint-down(sm){
            font-size: pxToEm(15);
            margin:18px 0 24px;
        }
        @include media-breakpoint-up(md){
            font-size: pxToEm(22);
            margin:18px 0 48px;
        }
    }
    .mtxt{
        @include media-breakpoint-up(lg){
            max-width: 420px;
        }
    }
    .bgtxt{
        @include media-breakpoint-down(sm){
            @include position(absolute,$right:-0.2em,$top:-0.2em);
        }
        @include media-breakpoint-up(md){
            @include position(absolute,$left:-1.5em,$bottom:-0.55em);
        }
    }
}
.indx .join-con{
    @include media-breakpoint-up(md){
        margin:0 -8px;
    }
    .join{
        @include media-breakpoint-down(sm){
            margin: 100px 0;
            .slogan{
                margin-bottom: 10px;
            }
        }
        @include media-breakpoint-up(md){
            margin: 200px 0;
            .slogan{
                margin-bottom: 20px;
            }
        }
        @include media-breakpoint-up(lg){            
            .textbox{
                padding-left: 35px;
            }
        }
        .bgtxt{
            @include media-breakpoint-down(sm){
                @include position(absolute,$left:-.6em,$top:-1.2em);
            }
            @include media-breakpoint-up(md){
                @include position(absolute,$right:-1.6em,$top:-0.7em);
            }
        }
    }
    .cont{
        @include media-breakpoint-up(md){
            .imgbox{
                order:2;
            }
            .textbox{
                order: 1;
            }
            //animation aos
            .textbox.revealOnScroll{
                transition-delay: 0.3s;
            }
        }
        .bgtxt{
            @include media-breakpoint-down(sm){
                @include position(absolute,$left:0,$top:-1.2em);
            }
            @include media-breakpoint-up(md){
                @include position(absolute,$left:-1.5em,$top:-0.7em);
            }
        }
    }
    .hoverbox{
        position: relative;
        @include media-breakpoint-up(lg){
            display: flex;
        }
    }
    .imgbox,.textbox{
        @include media-breakpoint-up(lg){
            flex:0 0 50%;
            width:50%;
            padding:0 8px;
        }
    }
    .imgbox{
        a{
            display: block;
            overflow: hidden;
        }
        @include media-breakpoint-down(lg){
            width:100%;
            margin-bottom: 16px;
        }
    }
    .bgcover{
        padding-bottom: 50%;
    }
    .textbox .mpsrel{
        height: 100%;
    }
    .btn_line{
        @include media-breakpoint-down(md){
            margin-top: 36px;
            display: block;
        }
        @include media-breakpoint-up(lg){                
            @include position(absolute,$left:0,$bottom:0);
        }
    }
}
//scroll line
@keyframes lineplus{
    0%{transform:translateY(-60px)}    
    100%{transform:translateY(180px)}
}
//circle animation
@keyframes pulse{
    0%{transform:scale(0.5);opacity:0}      
    5%{transform:scale(1);opacity:.5}
    50%{opacity:0.8}
    100%{transform:scale(2.5);opacity:0}
}