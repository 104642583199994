.form_des{
    @include font-color(black,300);
    text-align: center;
    @include media-breakpoint-down(xs){
        font-size: pxToEm(14);
        margin-bottom: 25px;
        i{
            display: block;
        }
    }
    @include media-breakpoint-up(sm){
        font-size: pxToEm(18);
        letter-spacing: 0.9px;
        margin-bottom: 35px;
    }
}
form{
    overflow: hidden; 
    letter-spacing: 0.75px;
    input,select,textarea{
        outline: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        box-shadow:none;
        width:100%;
        padding:0px 10px;
        background-color:#e6e6e6;
        border: none;
        @include border(0);
        font-size: pxToEm(15);
        @include font-color(black,300);
        &:focus { outline: none; }
    }
    input{
        @include placeholder {
            font-size: pxToEm(15);
            @include font-color(black,#707070);
        }
    }
    label{
        line-height: 1.5;
        @include font-color(black,300);
        @include media-breakpoint-down(xs){
            font-size: pxToEm(15); 
            margin-bottom: 4px;
        }
        @include media-breakpoint-up(sm){
            font-size: pxToEm(18); 
        }
    }
    label i{
        line-height: 0.5em;
        color:$red;
    }
    .form-group{
        @include media-breakpoint-up(sm){
            margin-bottom: 8px;
        }
    }
    .with-errors{
        flex:0 0 100%;
        min-height:14px;
        line-height:14px;
        letter-spacing: 0.7;
        @include font-color($red,300);  
        display:block;
        margin-top: 6px;
        @include media-breakpoint-down(xs){
            font-size: pxToEm(12); 
        }
        @include media-breakpoint-up(sm){
            font-size: pxToEm(14); 
        }
    }
    input{
        height:40px;
    }
    [type="submit"]{
        cursor: pointer;  
        outline: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        box-shadow:none;
        border:1px solid #707070;
    }
    //file-upload
    .file-upload{
        label span{
            @include font-color(#707070,300);
            line-height: 1;
            font-size: pxToEm(15);
            letter-spacing: 0.75;
            margin-left: 10px;
            display: inline-block;
        }
        .controls {
          display: none;
        }
        .mpath{
            word-break: break-all;
            margin-left: 10px;
        }
    }
    textarea{
        min-height: 257px;
    }
    .textarea-wrapper{
        height: 257px;
        overflow: hidden;
        padding:10px 0 10px 10px;
        background-color: #e6e6e6;
        textarea{
            min-height: 231px;
            overflow: hidden;
            border-bottom:none;
            padding:0;
            text-rendering: auto;
            -webkit-writing-mode: horizontal-tb !important;
            cursor:text;
        }
    }
}

.contact{
    //animation aos
    // @include media-breakpoint-up(md){
    //     .revealOnScroll:nth-child(2){
    //         transition-delay: 0.4s;
    //     }
    // }
    
    @include media-breakpoint-up(lg){
        .offset-lg-1{
            padding-right: 45px;
        }
    }
    h1,h4{
        line-height: 1.5;
        letter-spacing: 1.1;
        @include font-color(black,700);
    }
    h1{
        @include media-breakpoint-down(xs){
            font-size: pxToEm(16);
        }
        @include media-breakpoint-up(sm){
            font-size: pxToEm(24);
        }
    }
    h4{
        @include media-breakpoint-down(xs){
            font-size: pxToEm(15);
            border-bottom:2px solid black;
        }
        @include media-breakpoint-up(sm){
            font-size: pxToEm(22);
            border-bottom:3px solid black;
        }
        font-family: $fen;
        display: inline-block;
    }
    .info{
        @include media-breakpoint-down(xs){
            margin:20px 0 40px;
        }
        @include media-breakpoint-between(sm,md){
            margin-bottom: 40px;
        }
        @include media-breakpoint-up(sm){
            margin-top: 40px;
        }
        li{
            display: flex;
            @include media-breakpoint-down(xs){
                margin-bottom: 8px;
                font-size: pxToEm(14);
                padding-left: 25px;
                letter-spacing: 0;
            }
            @include media-breakpoint-up(sm){
                margin-bottom: 12px;
                font-size: pxToEm(18);
                padding-left: 25px;
            }
        }
        a[href^=tel],a[href^=mailto]{
            color:#707070;
            &:hover{
                color:inherit;
            }
        }
        li > a,
        li > span{
            @include font-color(#707070,300);
            letter-spacing: 0.9px;
        }
        i{
            display: inline-block;
            @include font-color(black,300);
            @include media-breakpoint-down(xs){
                width:70px;
                flex:0 0 70px;
            }
            @include media-breakpoint-up(sm){
                width:90px;
                flex:0 0 90px;
            }
        }
        .mtel{
            background: url(../images/icon/c_tel.png) no-repeat 1px 3px;
            @include media-breakpoint-down(xs){
                background-size:15px;
            }
            @include media-breakpoint-up(sm){
                background-size:20px;
            }
        }
        .mnum{
            background: url(../images/icon/c_num.png) no-repeat 1px 3px;
            @include media-breakpoint-down(xs){
                background-size:15px;
            }
            @include media-breakpoint-up(sm){
                background-size:20px;
            }
        }
        .mmail{
            background: url(../images/icon/c_mail.png) no-repeat 0px 6px;
            @include media-breakpoint-down(xs){
                background-size:15px;
            }
            @include media-breakpoint-up(sm){
                background-size:20px;
            }
        }
        .maddr{
            background: url(../images/icon/c_addr.png) no-repeat 4px 2px;
            @include media-breakpoint-down(xs){
                background-size:12px;
                background-position: 3px 3px;
            }
            @include media-breakpoint-up(sm){
                background-size:15px;
            }
        }
    }
    #map{
        box-shadow: 3.1px 3.9px 4.6px 0.5px rgba(0, 0, 0, 0.07);
    }
    .bggray{
        background-color: #f6f6f6;
        @include media-breakpoint-down(xs){
            padding: 30px 0 100px;
            margin-top: 32px;
        }
        @include media-breakpoint-up(sm){
            padding: 50px 0;
            margin-top: 76px;
        }
    }
    .control-group{
        @include media-breakpoint-down(md){
            margin-bottom: 20px;
        }
    }
    .text-center-sm{
        @include media-breakpoint-down(md){
            text-align: center;
        }
    }
}