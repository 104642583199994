@charset "UTF-8";
/******************************************/
/*		bootstrap-okadmin
/******************************************/
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+TC:300,400,500,700|Nunito+Sans:400,700&display=swap");
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #868e96;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #868e96; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efa2a9; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

/* > reset */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
  touch-action: manipulation; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.border {
  border: 1px solid #e9ecef !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #868e96 !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.pc_Banner .slick-slide {
  height: 371px; }

.mo_Banner .slick-slide {
  height: 200px; }

/* Slider */
/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url("../assets/slick.eot");
  src: url("../assets/slick.eot?#iefix") format("embedded-opentype"), url("../assets/slick.woff") format("woff"), url("../assets/slick.ttf") format("truetype"), url("../assets/slick.svg#slick") format("svg"); }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 99; }

/*.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}*/
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 1; }

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: 1;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px;
  /*    background: url(../images/are.png) 8px 0 no-repeat;*/ }

.slick-next {
  right: -25px;
  /*    background: url(../images/are.png) -10px 0 no-repeat;*/ }

/* Dots */
.slick-dots {
  position: absolute;
  display: block;
  width: 100%;
  padding: 0;
  margin: -39px 0 0 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 11px;
  height: 11px !important;
  margin: 0 10px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
  background: transparent !important; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  display: table;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
  -webkit-border-radius: 99em;
  -moz-border-radius: 99em;
  border-radius: 99em;
  background: #fff;
  content: '';
  text-align: center;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  background: #000; }

.topContent .slick-next, .topContent .slick-prev {
  display: none  !important; }

.topContent .slick-dots li {
  width: 20px; }

.topContent .slick-dots {
  position: relative;
  margin: 29px 0 0 0; }

.tagContent .slick-dots {
  margin: 0px 0 0 0; }

.tagContent .slick-dots li button:before {
  border: none; }

.exposeCase .topicContent .slick-dots {
  margin: 0px 0 0 0; }

.exposeCase .topicContent .slick-dots li {
  width: 20px; }

.exposeCase .topicContent .slick-dots li button:before {
  border: none;
  background: #959595; }

.exposeCase .topicContent .slick-dots li.slick-active button:before {
  border: none;
  background: #6bad3d; }

.learningBox .exposeCase .topicContent li {
  margin: 0px 4px 20px 0; }

.exposeCase .slick-prev {
  width: 46px;
  height: 108px;
  display: block;
  /* background: url(../images/le_ribtn.jpg) top left no-repeat; */
  left: -46px; }

.exposeCase .slick-next {
  width: 46px;
  height: 108px;
  display: block;
  /* background: url(../images/ex_ribtn.jpg) top left no-repeat; */
  right: -29px; }

.navigation .tagContent .slick-dots {
  margin: -13px 0 0 0;
  width: auto;
  text-align: right;
  position: relative;
  bottom: -11px; }

.eventBox .navigation .tagContent .slick-dots {
  margin: -13px 0 0 0;
  width: auto;
  text-align: right;
  position: relative;
  bottom: -26px; }

.navigation .slick-dots li button:before {
  background: #959595; }

.navigation .slick-dots li.slick-active button:before {
  background: #6bad3d; }

.recentBox .slick-dots {
  display: none !important; }

.recentBox .slick-list {
  width: 90%;
  max-width: 1000px;
  margin: 0 auto; }

.banner .slick-prev, .recentBox .slick-prev {
  left: 0px;
  width: 40px;
  height: 93px; }

.banner .slick-next, .recentBox .slick-next {
  right: 0px;
  width: 40px;
  height: 93px; }

.mCustomScrollbar {
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom; }

.mCustomScrollbar.mCS_no_scrollbar, .mCustomScrollbar.mCS_touch_action {
  -ms-touch-action: auto;
  touch-action: auto; }

.mCustomScrollBox {
  position: relative;
  overflow: hidden;
  height: 100%;
  max-width: 100%;
  outline: 0;
  direction: ltr; }

.mCSB_container {
  overflow: hidden;
  width: auto;
  height: auto; }

.mCSB_inside > .mCSB_container {
  margin-right: 30px; }

.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container {
  margin-right: 0;
  margin-left: 30px; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-left: 0; }

.mCSB_scrollTools {
  position: absolute;
  width: 16px;
  height: auto;
  left: auto;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: .75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCSB_outside + .mCSB_scrollTools {
  right: -26px; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_scrollTools, .mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  right: auto;
  left: 0; }

.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  left: -26px; }

.mCSB_scrollTools .mCSB_draggerContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto; }

.mCSB_scrollTools a + .mCSB_draggerContainer {
  margin: 20px 0; }

.mCSB_scrollTools .mCSB_draggerRail {
  width: 2px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px; }

.mCSB_scrollTools .mCSB_dragger {
  cursor: pointer;
  width: 100%;
  height: 30px;
  z-index: 1; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  position: relative;
  width: 4px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  text-align: center; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 12px; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 8px; }

.mCSB_scrollTools .mCSB_buttonDown, .mCSB_scrollTools .mCSB_buttonUp {
  display: block;
  position: absolute;
  height: 20px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer; }

.mCSB_scrollTools .mCSB_buttonDown {
  bottom: 0; }

.mCSB_horizontal.mCSB_inside > .mCSB_container {
  margin-right: 0;
  margin-bottom: 30px; }

.mCSB_horizontal.mCSB_outside > .mCSB_container {
  min-height: 100%; }

.mCSB_horizontal > .mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  width: auto;
  height: 16px;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0; }

.mCustomScrollBox + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal, .mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: -26px; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer {
  margin: 0 20px; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 2px;
  margin: 7px 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 30px;
  height: 100%;
  left: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  margin: 2px auto; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 8px;
  margin: 4px 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft, .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  display: block;
  position: absolute;
  width: 20px;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
  left: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  right: 0; }

.mCSB_container_wrapper {
  position: absolute;
  height: auto;
  width: auto;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-right: 30px;
  margin-bottom: 30px; }

.mCSB_container_wrapper > .mCSB_container {
  padding-right: 30px;
  padding-bottom: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 20px; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 20px; }

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden + .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 0; }

.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal, .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 0; }

.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 20px; }

.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 0; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container_wrapper {
  margin-right: 0;
  margin-left: 30px; }

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden > .mCSB_container {
  padding-right: 0; }

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden > .mCSB_container {
  padding-bottom: 0; }

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0;
  margin-left: 0; }

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0; }

.mCSB_scrollTools, .mCSB_scrollTools .mCSB_buttonDown, .mCSB_scrollTools .mCSB_buttonLeft, .mCSB_scrollTools .mCSB_buttonRight, .mCSB_scrollTools .mCSB_buttonUp, .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-transition: opacity .2s ease-in-out,background-color .2s ease-in-out;
  -moz-transition: opacity .2s ease-in-out,background-color .2s ease-in-out;
  -o-transition: opacity .2s ease-in-out,background-color .2s ease-in-out;
  transition: opacity .2s ease-in-out,background-color .2s ease-in-out; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail, .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar, .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail, .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar {
  -webkit-transition: width .2s ease-out .2s,height .2s ease-out .2s,margin-left .2s ease-out .2s,margin-right .2s ease-out .2s,margin-top .2s ease-out .2s,margin-bottom .2s ease-out .2s,opacity .2s ease-in-out,background-color .2s ease-in-out;
  -moz-transition: width .2s ease-out .2s,height .2s ease-out .2s,margin-left .2s ease-out .2s,margin-right .2s ease-out .2s,margin-top .2s ease-out .2s,margin-bottom .2s ease-out .2s,opacity .2s ease-in-out,background-color .2s ease-in-out;
  -o-transition: width .2s ease-out .2s,height .2s ease-out .2s,margin-left .2s ease-out .2s,margin-right .2s ease-out .2s,margin-top .2s ease-out .2s,margin-bottom .2s ease-out .2s,opacity .2s ease-in-out,background-color .2s ease-in-out;
  transition: width .2s ease-out .2s,height .2s ease-out .2s,margin-left .2s ease-out .2s,margin-right .2s ease-out .2s,margin-top .2s ease-out .2s,margin-bottom .2s ease-out .2s,opacity .2s ease-in-out,background-color .2s ease-in-out; }

.mCS-autoHide > .mCustomScrollBox > .mCSB_scrollTools, .mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 0;
  filter: "alpha(opacity=0)";
  -ms-filter: "alpha(opacity=0)"; }

.mCS-autoHide:hover > .mCustomScrollBox > .mCSB_scrollTools, .mCS-autoHide:hover > .mCustomScrollBox ~ .mCSB_scrollTools, .mCustomScrollBox:hover > .mCSB_scrollTools, .mCustomScrollBox:hover ~ .mCSB_scrollTools, .mCustomScrollbar > .mCustomScrollBox > .mCSB_scrollTools.mCSB_scrollTools_onDrag, .mCustomScrollbar > .mCustomScrollBox ~ .mCSB_scrollTools.mCSB_scrollTools_onDrag {
  opacity: 1;
  filter: "alpha(opacity=100)";
  -ms-filter: "alpha(opacity=100)"; }

.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.4);
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)"; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
  filter: "alpha(opacity=85)";
  -ms-filter: "alpha(opacity=85)"; }

.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)"; }

.mCSB_scrollTools .mCSB_buttonDown, .mCSB_scrollTools .mCSB_buttonLeft, .mCSB_scrollTools .mCSB_buttonRight, .mCSB_scrollTools .mCSB_buttonUp {
  background-image: none;
  background-repeat: no-repeat;
  opacity: .4;
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)"; }

.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 0; }

.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -20px; }

.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -40px; }

.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -56px; }

.mCSB_scrollTools .mCSB_buttonDown:hover, .mCSB_scrollTools .mCSB_buttonLeft:hover, .mCSB_scrollTools .mCSB_buttonRight:hover, .mCSB_scrollTools .mCSB_buttonUp:hover {
  opacity: .75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCSB_scrollTools .mCSB_buttonDown:active, .mCSB_scrollTools .mCSB_buttonLeft:active, .mCSB_scrollTools .mCSB_buttonRight:active, .mCSB_scrollTools .mCSB_buttonUp:active {
  opacity: .9;
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)"; }

.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail, .mCS-light-2.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-light-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-light-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-light-2.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 4px;
  margin: 6px auto; }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-light-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px 0; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -20px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -40px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -56px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-dark-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px 0; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -20px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -40px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -56px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail, .mCS-light-thick.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-light-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 4px;
  margin: 6px 0; }

.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 6px;
  margin: 5px auto; }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-light-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px 0; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -20px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -40px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -56px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px 0; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -20px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -40px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -56px; }

.mCS-light-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-light-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 2px; }

.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%; }

.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 2px;
  margin: 7px auto; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px; }

.mCS-rounded.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.15); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger, .mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger, .mCS-rounded.mCSB_scrollTools .mCSB_dragger {
  height: 14px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 14px;
  margin: 0 1px; }

.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 14px; }

.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 14px;
  margin: 1px 0; }

.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 16px;
  height: 16px;
  margin: -1px 0; }

.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail, .mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 4px; }

.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 16px;
  width: 16px;
  margin: 0 -1px; }

.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail, .mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 -72px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -92px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -112px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -128px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px -72px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -92px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -112px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -128px; }

.mCS-rounded-dots-dark.mCSB_scrollTools_vertical .mCSB_draggerRail, .mCS-rounded-dots.mCSB_scrollTools_vertical .mCSB_draggerRail {
  width: 4px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail, .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  background-color: transparent;
  background-position: center; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYV2NkIAAYiVbw//9/Y6DiM1ANJoyMjGdBbLgJQAX/kU0DKgDLkaQAvxW4HEvQFwCRcxIJK1XznAAAAABJRU5ErkJggg==);
  background-repeat: repeat-y;
  opacity: .3;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0;
  background-repeat: repeat-x; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px -72px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -92px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -112px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -128px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAALElEQVQYV2NkIAAYSVFgDFR8BqrBBEifBbGRTfiPZhpYjiQFBK3A6l6CvgAAE9kGCd1mvgEAAAAASUVORK5CYII=); }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px -72px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -92px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -112px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -128px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-y;
  background-image: -moz-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%); }

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-x;
  background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%); }

.mCS-3d-dark.mCSB_scrollTools_vertical .mCSB_dragger, .mCS-3d.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 70px; }

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 70px; }

.mCS-3d-dark.mCSB_scrollTools, .mCS-3d.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_draggerRail {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-3d.mCSB_scrollTools .mCSB_draggerRail {
  width: 8px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.5), inset -1px 0 1px rgba(255, 255, 255, 0.2); }

.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #555; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 8px; }

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-3d.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 8px;
  margin: 4px 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), inset 0 -1px 1px rgba(255, 255, 255, 0.2); }

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 8px;
  margin: 4px auto; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1); }

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1); }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

.mCS-3d-thick-dark.mCSB_scrollTools, .mCS-3d-thick.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-3d-thick-dark.mCSB_scrollTools, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer, .mCS-3d-thick.mCSB_scrollTools, .mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px; }

.mCSB_inside + .mCS-3d-thick-dark.mCSB_scrollTools_vertical, .mCSB_inside + .mCS-3d-thick.mCSB_scrollTools_vertical {
  right: 1px; }

.mCS-3d-thick-dark.mCSB_scrollTools_vertical, .mCS-3d-thick.mCSB_scrollTools_vertical {
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal, .mCS-3d-thick.mCSB_scrollTools_horizontal {
  bottom: 1px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
  width: 12px;
  margin: 2px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4);
  height: 12px;
  width: auto; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #555; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

.mCS-3d-thick-dark.mCSB_scrollTools {
  box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4), inset -1px 0 0 rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #777; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #fff;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-minimal.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

.mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical, .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical {
  right: 0;
  margin: 12px 0; }

.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal, .mCustomScrollBox.mCS-minimal + .mCSB_scrollTools.mCSB_scrollTools_horizontal, .mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal, .mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: 0;
  margin: 0 12px; }

.mCS-dir-rtl > .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical, .mCS-dir-rtl > .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical {
  left: 0;
  right: auto; }

.mCS-minimal-dark.mCSB_scrollTools_vertical .mCSB_dragger, .mCS-minimal.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 50px; }

.mCS-minimal-dark.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-minimal.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 50px; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)"; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-minimal.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)"; }

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)"; }

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)"; }

.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail, .mCS-light-3.mCSB_scrollTools .mCSB_draggerRail {
  width: 6px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-light-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px; }

.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-light-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-light-3.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 6px;
  margin: 5px 0; }

.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail, .mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 12px; }

.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail, .mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 12px;
  margin: 2px 0; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-dark-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1); }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset.mCSB_scrollTools .mCSB_draggerRail {
  width: 12px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2); }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  margin: 3px 5px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 6px;
  margin: 5px 3px;
  position: absolute;
  width: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 12px;
  margin: 2px 0; }

.mCS-inset-2.mCSB_scrollTools .mCSB_buttonUp, .mCS-inset-3.mCSB_scrollTools .mCSB_buttonUp, .mCS-inset.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-inset-2.mCSB_scrollTools .mCSB_buttonDown, .mCS-inset-3.mCSB_scrollTools .mCSB_buttonDown, .mCS-inset.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-inset-2.mCSB_scrollTools .mCSB_buttonLeft, .mCS-inset-3.mCSB_scrollTools .mCSB_buttonLeft, .mCS-inset.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-inset-2.mCSB_scrollTools .mCSB_buttonRight, .mCS-inset-3.mCSB_scrollTools .mCSB_buttonRight, .mCS-inset.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1); }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonUp, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonUp, .mCS-inset-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonDown, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonDown, .mCS-inset-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonLeft, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonLeft, .mCS-inset-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonRight, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonRight, .mCS-inset-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  border-color: rgba(255, 255, 255, 0.2);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  border-color: #000;
  border-color: rgba(0, 0, 0, 0.2); }

.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.6); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.6); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-inset-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

/******************************************/
/*		base 歸零 變數
/******************************************/
/* http://meyerweb.com/eric/tools/css/reset/ */
/* v1.0 | 20080212 */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
caption,
tfoot,
thead,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  /* vertical-align: baseline; */ }

tr,
th,
td {
  margin: 0;
  padding: 0;
  font-size: 100%;
  font: inherit; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

/*ol, ul {
	list-style: none;
}*/
blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

/*背景沒連結時用*/
.myMOUSE {
  cursor: default; }

.error {
  font-size: 12px;
  color: #fc7314; }

/******************************************/
/*		layout 共用
/******************************************/
/*----------------------通用設定------------------------*/
main[role^=main] {
  zoom: 1; }
  main[role^=main]:before, main[role^=main]:after {
    content: "";
    display: table;
    height: 0;
    overflow: hidden; }
  main[role^=main]:after {
    clear: both; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

p::selection, h1::selection, h2::selection, h3::selection, h4::selection, b::selection, strong::selection, span::selection, li::selection, div::selection, a::selection, img::selection {
  color: #fff;
  background: #000; }

a {
  outline: none; }

a, a:hover {
  text-decoration: none;
  transition: 0.4s;
  color: initial; }

*:focus {
  outline: none; }

img {
  vertical-align: middle;
  max-width: 100%; }

ul {
  list-style-type: none; }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

/*----------------------編輯器------------------------*/
.editor_inner {
  font-size: 0.9375rem;
  color: #707070;
  font-weight: 300;
  letter-spacing: 0.75px; }
  @media (min-width: 576px) {
    .editor_inner {
      line-height: 1.5; } }
  @media (min-width: 576px) {
    .editor_inner {
      line-height: 1.87; } }
  .editor_inner ul, .editor_inner ol {
    padding-left: 40px; }
  .editor_inner img {
    max-width: 100%;
    height: auto !important; }
  .editor_inner iframe {
    max-width: 100%; }
    @media screen and (max-width: 767px) {
      .editor_inner iframe {
        height: auto !important; } }
  .editor_inner h1, .editor_inner h2, .editor_inner h3, .editor_inner h4, .editor_inner h5, .editor_inner h6 {
    margin: inherit; }

/*----------------------頁籤------------------------*/
.page {
  margin: 0px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center; }
  .page dt {
    margin-top: -12px; }
    .page dt i {
      display: inline-block;
      height: 1px;
      background-color: #a0a0a0;
      position: relative;
      transition: transform 0.6s;
      width: 25px; }
    .page dt.rtbn i:after {
      content: ' ';
      display: inline-block;
      width: 10px;
      height: 1px;
      transform: rotate(45deg);
      transform-origin: right;
      background-color: #a0a0a0;
      position: absolute;
      top: 0;
      right: 0; }
    .page dt.ltbn i:before {
      content: ' ';
      display: inline-block;
      width: 10px;
      height: 1px;
      transform: rotate(-45deg);
      transform-origin: left;
      background-color: #a0a0a0;
      position: absolute;
      top: 0;
      left: 0; }
    @media (min-width: 768px) {
      .page dt.rtbn a:not(.nopage):hover i {
        transform: translateX(15px); }
      .page dt.ltbn a:not(.nopage):hover i {
        transform: translateX(-15px); } }
    .page dt .nopage {
      z-index: -1;
      opacity: 0.5;
      cursor: default; }
  .page dd {
    font-family: "Nunito Sans", "Microsoft JhengHei", "微軟正黑體", sans-serif;
    position: relative; }
    .page dd:nth-child(2) {
      padding-left: 15px; }
    .page dd:nth-last-child(2) {
      padding-right: 15px; }
    .page dd:after {
      content: ' ';
      display: block;
      width: 100%;
      height: 1px;
      background-color: #a0a0a0;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1; }
    .page dd a {
      display: block;
      color: #a0a0a0;
      position: relative; }
      .page dd a i {
        border-bottom: 2px solid rgba(0, 0, 0, 0);
        line-height: 1.5;
        display: block;
        padding: 0 2px 6px;
        transition: border 0.6s; }
      @media (max-width: 767.99px) {
        .page dd a {
          padding: 0 10px;
          margin: 0 5px; }
          .page dd a i {
            font-size: 0.75rem; } }
      @media (min-width: 768px) {
        .page dd a {
          padding: 0 10px;
          margin: 0 10px; }
          .page dd a i {
            font-size: 0.9375rem; }
          .page dd a:hover {
            color: black; }
            .page dd a:hover i {
              border-color: black; } }
    .page dd a.active {
      color: black; }
      .page dd a.active i {
        border-color: black; }

/*----------------------go top------------------------*/
#topBtn {
  text-align: center;
  display: block;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  width: 56px;
  height: 60px;
  color: #707070;
  font-weight: 400;
  letter-spacing: 0.75;
  line-height: 1;
  font-size: 0.9375rem; }
  #topBtn div {
    margin-top: 48px; }
  #topBtn .nohover {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1; }
  #topBtn .mhover {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0; }
  #topBtn.open {
    opacity: 1;
    visibility: visible;
    z-index: 100; }
  #topBtn.change {
    position: absolute; }
  @media (max-width: 575.99px) {
    #topBtn {
      position: fixed;
      right: 10px;
      bottom: 30px;
      transform-origin: right bottom;
      transform: scale(0.75); } }
  @media (min-width: 576px) {
    #topBtn {
      position: fixed;
      right: 30px;
      bottom: 40px; } }
  @media (min-width: 992px) {
    #topBtn:hover .mhover {
      opacity: 1; }
    #topBtn:hover .nohover {
      opacity: 0; } }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.text-justify {
  text-align: justify; }

.mpsrel {
  position: relative; }

.hide {
  display: none; }

.bgcover {
  width: 100%;
  height: 0;
  background: center / cover; }

.rowbox {
  margin: 0 -15px 0 -15px;
  position: relative;
  max-width: 1200px;
  padding: 0 15px; }

.row.col-md-center {
  justify-content: center; }

@media (max-width: 991.99px) {
  .show-lg {
    display: none; }
  .bgcover[data-src-lg] {
    content: attr(data-src-lg, url); } }

@media (min-width: 992px) {
  .show-sm {
    display: none; }
  .bgcover[data-src-sm] {
    content: attr(data-src-sm, url); } }

header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%; }
  @media (min-width: 576px) {
    header {
      padding: 15px 0px; } }
  header .title-bar {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 5px 4.6px 0.5px rgba(0, 0, 0, 0.07);
    width: 100%;
    transition: background 0.6s; }
    @media (max-width: 767.99px) {
      header .title-bar {
        height: 55px;
        z-index: 100; } }
    @media (min-width: 768px) {
      header .title-bar {
        height: 65px; } }
  @media (max-width: 767.99px) {
    header ul.mmenu {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%); } }
  @media (min-width: 768px) {
    header ul.mmenu {
      display: flex;
      justify-content: flex-end;
      align-items: center; } }
  @media (min-width: 768px) and (max-width: 991.99px) {
    header ul.mmenu {
      padding-right: 20px; } }
  @media (min-width: 992px) {
    header ul.mmenu {
      padding-right: 60px; } }
  header ul.mmenu > li a {
    position: relative;
    font-size: 1rem;
    color: black;
    font-weight: 300;
    letter-spacing: 0.8;
    line-height: 36px;
    display: block; }
    header ul.mmenu > li a:after {
      content: ' ';
      display: block;
      width: 100%;
      height: 1px;
      background-color: black;
      margin-top: -3px;
      opacity: 0;
      transition: opacity 0.6s; }
  @media (max-width: 767.99px) {
    header ul.mmenu > li {
      text-align: center;
      display: block; }
      header ul.mmenu > li a {
        padding: 15px 0;
        margin: 10px 0; } }
  @media (min-width: 768px) {
    header ul.mmenu > li {
      margin-left: 35px; }
      header ul.mmenu > li a:hover:after,
      header ul.mmenu > li.active a:after {
        opacity: 1; } }
  header .menuicon {
    position: absolute;
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    z-index: 10; }
    header .menuicon .mpsrel {
      width: 37px;
      height: 37px; }
    header .menuicon .bar, header .menuicon .bar:after, header .menuicon .bar:before {
      transition: all .25s;
      content: "";
      position: absolute;
      left: 50%;
      top: 0;
      height: 2px;
      width: 26px;
      background: black;
      transform: translateX(-50%); }
    header .menuicon .bar {
      margin-top: 18px; }
    header .menuicon .bar:after {
      top: 10px; }
    header .menuicon .bar:before {
      top: -10px; }
    @media (min-width: 768px) {
      header .menuicon {
        display: none; } }
  header.menu-expanded .title-bar {
    background-color: white; }
  header.menu-expanded .menuicon .bar {
    background: rgba(0, 0, 0, 0); }
    header.menu-expanded .menuicon .bar:before {
      top: 0px;
      transform: translateX(-50%) rotate(-45deg);
      background-color: black; }
    header.menu-expanded .menuicon .bar:after {
      top: 0px;
      transform: translateX(-50%) rotate(45deg);
      background-color: black; }
  header .logo {
    transform: translateY(-50%); }
    @media (max-width: 991.99px) {
      header .logo {
        position: absolute;
        top: 50%;
        left: 20px;
        width: 70px; } }
    @media (min-width: 992px) {
      header .logo {
        position: absolute;
        top: 50%;
        left: 60px;
        width: 80px; } }
  @media (max-width: 767.99px) {
    header .navBox {
      display: none;
      width: 100%;
      height: calc(100vh - 55px);
      position: fixed;
      top: 55px;
      left: 0;
      z-index: 99;
      background-color: white;
      -webkit-overflow-scrolling: touch; } }

footer {
  min-height: 50px;
  width: 100%;
  position: relative;
  background-color: #aaaaaa;
  font-size: 0.875rem;
  color: white;
  font-weight: 300;
  letter-spacing: 0.7;
  line-height: 1.5; }
  @media (max-width: 575.99px) {
    footer {
      font-size: 0.75rem; } }
  @media (max-width: 767.99px) {
    footer {
      padding: 16px 20px 4px 100px; } }
  @media (min-width: 768px) {
    footer {
      padding: 16px 60px 4px 200px; } }
  @media (min-width: 768px) and (max-width: 991.99px) {
    footer {
      padding: 16px 20px 4px 160px; } }
  footer a[href^=tel], footer a[href^=mailto] {
    color: white; }
    footer a[href^=tel]:hover, footer a[href^=mailto]:hover {
      color: inherit; }
  footer ul {
    height: 100%;
    display: flex;
    flex-wrap: wrap; }
    @media (min-width: 576px) and (max-width: 767.99px) {
      footer ul {
        width: 80%; } }
    @media (min-width: 992px) {
      footer ul {
        justify-content: space-between; } }
  footer li.info {
    display: inline-flex;
    flex-wrap: wrap; }
    @media (max-width: 767.99px) {
      footer li.info a:nth-child(1) {
        margin-right: 30px; } }
    @media (min-width: 768px) {
      footer li.info a {
        margin-right: 30px; }
      footer li.info span {
        margin-right: 70px; } }
  footer .logo,
  footer .copyright,
  footer li.info a,
  footer li.info span {
    margin-bottom: 8px; }
  footer .logo {
    transform: translateY(-50%); }
    @media (max-width: 767.99px) {
      footer .logo {
        width: 60px;
        position: absolute;
        top: 50%;
        left: 20px; } }
    @media (min-width: 768px) {
      footer .logo {
        width: 80px;
        position: absolute;
        top: 50%;
        left: 60px; } }
    @media (min-width: 768px) and (max-width: 991.99px) {
      footer .logo {
        left: 20px; } }
  footer .mtel {
    background: url(../images/icon/f_tel.png) no-repeat 1px 3px/15px;
    padding-left: 25px; }
  footer .mmail {
    background: url(../images/icon/f_mail.png) no-repeat 2px 5px/15px;
    padding-left: 25px; }
  footer .maddr {
    padding-left: 25px; }
    @media (max-width: 575.99px) {
      footer .maddr {
        background: url(../images/icon/f_addr.png) no-repeat 3px 1px/10px; } }
    @media (min-width: 576px) {
      footer .maddr {
        background: url(../images/icon/f_addr.png) no-repeat 5px 3px/10px; } }

.lang_tw .en {
  display: none; }

.lang_tw .tw {
  display: block; }

.lang_en .en {
  display: block; }

.lang_en .tw {
  display: none; }

/******************************************/
/*		pages
/******************************************/
body {
  font-family: "Nunito Sans", "Noto Sans TC", "Microsoft JhengHei", "微軟正黑體", sans-serif;
  font-size: 0.9375rem;
  line-height: 1.5;
  color: #707070;
  font-weight: 300;
  overflow-x: hidden;
  opacity: 0;
  visibility: hidden; }
  body.fade-in {
    opacity: 1;
    transition: opacity  1.5s ease;
    visibility: visible; }

body.modal_open main {
  height: calc(100vh - 40px);
  overflow: hidden; }

main.pd0 {
  padding-bottom: 0; }

@media (max-width: 575.99px) {
  main .container, main .col-12 {
    padding-left: 20px;
    padding-right: 20px; }
  main .row {
    margin-right: -20px;
    margin-left: -20px; } }

@media (max-width: 767.99px) {
  main {
    padding-bottom: 80px; }
    main:not(.indx) {
      background: no-repeat url(../images/innerbg_sm.jpg) top right/contain; } }

@media (min-width: 768px) {
  main {
    padding-bottom: 120px; }
    main:not(.indx) {
      background: no-repeat url(../images/innerbg.jpg) top right/2000px; }
    main .container {
      max-width: 1200px; } }

.btn_box {
  min-width: 110px;
  background-color: #707070;
  display: inline-block;
  text-align: center;
  color: white;
  font-weight: 400;
  border: 1px solid #707070; }
  .btn_box:not(.joinbtn) {
    height: 40px;
    line-height: 40px;
    letter-spacing: 0.75px; }
  @media (max-width: 575.99px) {
    .btn_box:hover {
      color: white; } }
  @media (min-width: 576px) {
    .btn_box:hover {
      background-color: white;
      color: #707070; } }

.btn_line {
  color: #707070;
  font-weight: 400;
  font-family: "Nunito Sans", "Microsoft JhengHei", "微軟正黑體", sans-serif;
  letter-spacing: 0.78px; }
  @media (max-width: 991.99px) {
    .btn_line {
      font-size: 0.875rem; } }
  @media (min-width: 576px) {
    .btn_line {
      font-size: 1rem; } }
  .btn_line:hover {
    color: #707070; }
  .btn_line i {
    display: inline-block;
    height: 1px;
    background-color: #a0a0a0;
    position: relative;
    vertical-align: 1px;
    transition: transform 0.6s; }
    @media (max-width: 575.99px) {
      .btn_line i {
        width: 40px; } }
    @media (min-width: 576px) {
      .btn_line i {
        width: 80px; } }
  .btn_line.mr i {
    margin-left: 10px; }
  .btn_line.mr i:after {
    content: ' ';
    display: inline-block;
    width: 8px;
    height: 1px;
    transform: rotate(45deg);
    transform-origin: right;
    background-color: #a0a0a0;
    position: absolute;
    top: 0;
    right: 0; }
  .btn_line.ml i {
    margin-right: 10px; }
  .btn_line.ml i:before {
    content: ' ';
    display: inline-block;
    width: 8px;
    height: 1px;
    transform: rotate(-45deg);
    transform-origin: left;
    background-color: #a0a0a0;
    position: absolute;
    top: 0;
    left: 0; }
  @media (min-width: 768px) {
    .btn_line.ml:hover i {
      transform: translateX(-15px); } }

.hoverbox .bgcover {
  transform: scale(1);
  transition: transform 0.6s; }

@media (min-width: 768px) {
  .hoverbox:hover .bgcover {
    transform: scale(1.1); }
  .hoverbox:hover .btn_line.mr i {
    transform: translateX(15px); }
  .hoverbox:hover .btn_line.ml i {
    transform: translateX(-15px); } }

.pgkv {
  line-height: 1;
  display: flex;
  text-align: center; }
  @media (max-width: 767.99px) {
    .pgkv {
      height: 220px;
      align-items: center; } }
  @media (min-width: 768px) {
    .pgkv {
      height: 330px; } }
  .pgkv > div {
    flex: 0 0 100%; }
    @media (max-width: 767.99px) {
      .pgkv > div {
        padding-top: 75px; } }
    @media (min-width: 768px) {
      .pgkv > div {
        padding-top: 185px; } }
  .pgkv .title-en {
    font-family: "Nunito Sans", "Microsoft JhengHei", "微軟正黑體", sans-serif;
    color: black;
    font-weight: 700; }
    @media (max-width: 575.99px) {
      .pgkv .title-en {
        font-size: 1.25rem; } }
    @media (min-width: 576px) {
      .pgkv .title-en {
        font-size: 2rem; } }
  .pgkv .title-ch {
    margin-top: 12px;
    color: black;
    font-weight: 400; }
    @media (max-width: 575.99px) {
      .pgkv .title-ch {
        font-size: 1rem;
        margin-top: 6px; } }
    @media (min-width: 576px) {
      .pgkv .title-ch {
        font-size: 1.5rem;
        margin-top: 12px; } }

div.revealOnScroll {
  opacity: 0;
  transform: translateY(60px);
  transition: opacity 1.5s ,transform 1.5s; }

div.revealOnScroll.animated {
  opacity: 1;
  transform: translateY(0px); }

#lightcase-case {
  padding: 0 15px; }
  #lightcase-case iframe {
    max-width: calc(100vw - 30px) !important; }

#lightcase-overlay {
  background-color: rgba(0, 0, 0, 0.8); }

@media (max-width: 575.99px) {
  a[class*="lightcase-icon-"].lightcase-icon-close {
    width: 20px;
    height: 20px;
    background-size: 20px; } }

@media (min-width: 576px) {
  a[class*="lightcase-icon-"].lightcase-icon-close {
    width: 34px;
    height: 34px;
    background-size: 34px; } }

a[class*="lightcase-icon-"].lightcase-icon-close:before {
  display: none; }

.slickbox {
  position: relative; }
  .slickbox .slick-dots {
    text-align: right; }
    @media (max-width: 575.99px) {
      .slickbox .slick-dots li {
        margin: 0;
        margin-left: 10px;
        width: 18px;
        height: 3px !important; } }
    @media (min-width: 576px) {
      .slickbox .slick-dots li {
        margin: 0;
        margin-left: 17px;
        width: 36px;
        height: 5px !important; } }
    .slickbox .slick-dots li.slick-active span {
      background-color: black; }
    .slickbox .slick-dots span {
      display: block;
      width: 100%;
      height: 100%;
      background-color: white; }

.hiddendiv {
  display: none; }

.mCSB_inside > .mCSB_container {
  margin-right: 16px; }

.mCSB_scrollTools {
  opacity: 1; }

.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #707070;
  width: 1px; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 2px;
  background-color: black !important;
  opacity: 1 !important; }

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: black; }

.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  opacity: 1 !important; }

.form_des {
  color: black;
  font-weight: 300;
  text-align: center; }
  @media (max-width: 575.99px) {
    .form_des {
      font-size: 0.875rem;
      margin-bottom: 25px; }
      .form_des i {
        display: block; } }
  @media (min-width: 576px) {
    .form_des {
      font-size: 1.125rem;
      letter-spacing: 0.9px;
      margin-bottom: 35px; } }

form {
  overflow: hidden;
  letter-spacing: 0.75px; }
  form input, form select, form textarea {
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    box-shadow: none;
    width: 100%;
    padding: 0px 10px;
    background-color: #e6e6e6;
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    font-size: 0.9375rem;
    color: black;
    font-weight: 300; }
    form input:focus, form select:focus, form textarea:focus {
      outline: none; }
  form input ::-webkit-input-placeholder {
    font-size: 0.9375rem;
    color: black;
    font-weight: #707070; }
  form input :-moz-placeholder {
    font-size: 0.9375rem;
    color: black;
    font-weight: #707070; }
  form input ::-moz-placeholder {
    font-size: 0.9375rem;
    color: black;
    font-weight: #707070; }
  form input :-ms-input-placeholder {
    font-size: 0.9375rem;
    color: black;
    font-weight: #707070; }
  form label {
    line-height: 1.5;
    color: black;
    font-weight: 300; }
    @media (max-width: 575.99px) {
      form label {
        font-size: 0.9375rem;
        margin-bottom: 4px; } }
    @media (min-width: 576px) {
      form label {
        font-size: 1.125rem; } }
  form label i {
    line-height: 0.5em;
    color: #ae0000; }
  @media (min-width: 576px) {
    form .form-group {
      margin-bottom: 8px; } }
  form .with-errors {
    flex: 0 0 100%;
    min-height: 14px;
    line-height: 14px;
    letter-spacing: 0.7;
    color: #ae0000;
    font-weight: 300;
    display: block;
    margin-top: 6px; }
    @media (max-width: 575.99px) {
      form .with-errors {
        font-size: 0.75rem; } }
    @media (min-width: 576px) {
      form .with-errors {
        font-size: 0.875rem; } }
  form input {
    height: 40px; }
  form [type="submit"] {
    cursor: pointer;
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    box-shadow: none;
    border: 1px solid #707070; }
  form .file-upload label span {
    color: #707070;
    font-weight: 300;
    line-height: 1;
    font-size: 0.9375rem;
    letter-spacing: 0.75;
    margin-left: 10px;
    display: inline-block; }
  form .file-upload .controls {
    display: none; }
  form .file-upload .mpath {
    word-break: break-all;
    margin-left: 10px; }
  form textarea {
    min-height: 257px; }
  form .textarea-wrapper {
    height: 257px;
    overflow: hidden;
    padding: 10px 0 10px 10px;
    background-color: #e6e6e6; }
    form .textarea-wrapper textarea {
      min-height: 231px;
      overflow: hidden;
      border-bottom: none;
      padding: 0;
      text-rendering: auto;
      -webkit-writing-mode: horizontal-tb !important;
      cursor: text; }

@media (min-width: 992px) {
  .contact .offset-lg-1 {
    padding-right: 45px; } }

.contact h1, .contact h4 {
  line-height: 1.5;
  letter-spacing: 1.1;
  color: black;
  font-weight: 700; }

@media (max-width: 575.99px) {
  .contact h1 {
    font-size: 1rem; } }

@media (min-width: 576px) {
  .contact h1 {
    font-size: 1.5rem; } }

.contact h4 {
  font-family: "Nunito Sans", "Microsoft JhengHei", "微軟正黑體", sans-serif;
  display: inline-block; }
  @media (max-width: 575.99px) {
    .contact h4 {
      font-size: 0.9375rem;
      border-bottom: 2px solid black; } }
  @media (min-width: 576px) {
    .contact h4 {
      font-size: 1.375rem;
      border-bottom: 3px solid black; } }

@media (max-width: 575.99px) {
  .contact .info {
    margin: 20px 0 40px; } }

@media (min-width: 576px) and (max-width: 991.99px) {
  .contact .info {
    margin-bottom: 40px; } }

@media (min-width: 576px) {
  .contact .info {
    margin-top: 40px; } }

.contact .info li {
  display: flex; }
  @media (max-width: 575.99px) {
    .contact .info li {
      margin-bottom: 8px;
      font-size: 0.875rem;
      padding-left: 25px;
      letter-spacing: 0; } }
  @media (min-width: 576px) {
    .contact .info li {
      margin-bottom: 12px;
      font-size: 1.125rem;
      padding-left: 25px; } }

.contact .info a[href^=tel], .contact .info a[href^=mailto] {
  color: #707070; }
  .contact .info a[href^=tel]:hover, .contact .info a[href^=mailto]:hover {
    color: inherit; }

.contact .info li > a,
.contact .info li > span {
  color: #707070;
  font-weight: 300;
  letter-spacing: 0.9px; }

.contact .info i {
  display: inline-block;
  color: black;
  font-weight: 300; }
  @media (max-width: 575.99px) {
    .contact .info i {
      width: 70px;
      flex: 0 0 70px; } }
  @media (min-width: 576px) {
    .contact .info i {
      width: 90px;
      flex: 0 0 90px; } }

.contact .info .mtel {
  background: url(../images/icon/c_tel.png) no-repeat 1px 3px; }
  @media (max-width: 575.99px) {
    .contact .info .mtel {
      background-size: 15px; } }
  @media (min-width: 576px) {
    .contact .info .mtel {
      background-size: 20px; } }

.contact .info .mnum {
  background: url(../images/icon/c_num.png) no-repeat 1px 3px; }
  @media (max-width: 575.99px) {
    .contact .info .mnum {
      background-size: 15px; } }
  @media (min-width: 576px) {
    .contact .info .mnum {
      background-size: 20px; } }

.contact .info .mmail {
  background: url(../images/icon/c_mail.png) no-repeat 0px 6px; }
  @media (max-width: 575.99px) {
    .contact .info .mmail {
      background-size: 15px; } }
  @media (min-width: 576px) {
    .contact .info .mmail {
      background-size: 20px; } }

.contact .info .maddr {
  background: url(../images/icon/c_addr.png) no-repeat 4px 2px; }
  @media (max-width: 575.99px) {
    .contact .info .maddr {
      background-size: 12px;
      background-position: 3px 3px; } }
  @media (min-width: 576px) {
    .contact .info .maddr {
      background-size: 15px; } }

.contact #map {
  box-shadow: 3.1px 3.9px 4.6px 0.5px rgba(0, 0, 0, 0.07); }

.contact .bggray {
  background-color: #f6f6f6; }
  @media (max-width: 575.99px) {
    .contact .bggray {
      padding: 30px 0 100px;
      margin-top: 32px; } }
  @media (min-width: 576px) {
    .contact .bggray {
      padding: 50px 0;
      margin-top: 76px; } }

@media (max-width: 991.99px) {
  .contact .control-group {
    margin-bottom: 20px; } }

@media (max-width: 991.99px) {
  .contact .text-center-sm {
    text-align: center; } }

.indx {
  overflow-x: hidden; }
  .indx .mtitle {
    color: black;
    font-weight: 700;
    line-height: 1; }
    @media (max-width: 575.99px) {
      .indx .mtitle {
        font-size: 1rem;
        margin-bottom: 16px; } }
    @media (min-width: 576px) {
      .indx .mtitle {
        font-size: 1.5rem;
        margin-bottom: 40px; } }
  @media (max-width: 575.99px) {
    .indx .mtxt {
      line-height: 1.5;
      font-size: 0.875rem; } }
  @media (min-width: 576px) {
    .indx .mtxt {
      line-height: 1.87; } }
  .indx .slogan {
    line-height: 1.4;
    letter-spacing: 1.6px;
    color: black;
    font-weight: 400;
    position: relative; }
    @media (max-width: 575.99px) {
      .indx .slogan {
        font-size: 1.125rem;
        margin-bottom: 10px; } }
    @media (min-width: 576px) {
      .indx .slogan {
        font-size: 2rem;
        margin-bottom: 30px; } }
  .indx .bgtxt {
    line-height: 1;
    font-family: "Nunito Sans", "Microsoft JhengHei", "微軟正黑體", sans-serif;
    color: #f6f6f6;
    font-weight: 700;
    opacity: 0;
    z-index: -1; }
    @media (max-width: 767.99px) {
      .indx .bgtxt {
        font-size: 3.125rem; } }
    @media (min-width: 768px) {
      .indx .bgtxt {
        font-size: 8.5rem; } }
    .indx .bgtxt.revealOnScroll {
      transform: translateY(0);
      transition: opacity 1s;
      transition-delay: 0.2s; }
    .indx .bgtxt.revealOnScroll.animated {
      opacity: 0.8; }
  .indx .bgmap {
    z-index: -1; }
    @media (max-width: 991.99px) {
      .indx .bgmap {
        position: absolute;
        bottom: 0%;
        left: -17%;
        width: 115%; }
        .indx .bgmap.show-l {
          display: none; }
        .indx .bgmap + .bgimgH {
          width: 100%;
          padding-bottom: 55%; } }
    @media (min-width: 992px) {
      .indx .bgmap {
        position: absolute;
        top: 5%;
        right: 5%;
        width: 58%; }
        .indx .bgmap.show-m {
          display: none; } }
  @media (max-width: 575.99px) {
    .indx .circlebox {
      position: absolute;
      top: 40%;
      right: 12.5%; } }
  @media (min-width: 576px) {
    .indx .circlebox {
      position: absolute;
      top: 39%;
      right: 12%; } }
  .indx .circlebox span {
    display: block;
    -webkit-border-radius: 99em;
    -moz-border-radius: 99em;
    border-radius: 99em;
    border: 1px solid rgba(178, 178, 178, 0.5);
    animation: pulse 3s infinite ease-out;
    position: absolute;
    top: 0%;
    right: 0%;
    opacity: 0; }
    @media (max-width: 575.99px) {
      .indx .circlebox span {
        width: 30px;
        height: 30px;
        border-color: rgba(178, 178, 178, 0.2); } }
    @media (min-width: 576px) {
      .indx .circlebox span {
        width: 50px;
        height: 50px; } }
  .indx .circlebox span:nth-child(1) {
    animation-delay: 0s; }
  .indx .circlebox span:nth-child(2) {
    animation-delay: 0.5s; }
  .indx .circlebox span:nth-child(3) {
    animation-delay: 1.5s; }
  .indx .circlebox span:nth-child(4) {
    animation-delay: 2s; }
  .indx .circlebox span:nth-child(5) {
    animation-delay: 2.5s; }

@media (min-width: 768px) {
  .indx-banner .slider-banner, .indx-banner .bgcover {
    width: 100%;
    height: 100vh; } }

@media (max-width: 767.99px) {
  .indx-banner .slider-banner, .indx-banner .bgcover {
    padding-bottom: 97%;
    height: 0; } }

.indx-banner .slick-dots {
  margin: 0; }
  @media (max-width: 767.99px) {
    .indx-banner .slick-dots {
      position: absolute;
      right: 20px;
      bottom: 10px; } }
  @media (min-width: 768px) {
    .indx-banner .slick-dots {
      position: absolute;
      right: 60px;
      bottom: 30px; } }

.indx-banner .slick-txt {
  z-index: 10;
  width: 80%; }
  @media (max-width: 767.99px) {
    .indx-banner .slick-txt {
      position: absolute;
      top: 30%;
      left: 20px; } }
  @media (min-width: 768px) {
    .indx-banner .slick-txt {
      position: absolute;
      bottom: 180px;
      left: 160px;
      max-width: 600px; } }
  .indx-banner .slick-txt h2 {
    line-height: 1.45;
    font-size: 2.625rem;
    color: black;
    font-weight: 700;
    font-family: 'Merriweather Sans', sans-serif;
    margin-bottom: 10px; }
    @media (max-width: 575.99px) {
      .indx-banner .slick-txt h2 {
        font-size: 1.25rem; } }
    @media (min-width: 576px) {
      .indx-banner .slick-txt h2 {
        font-size: 2.625rem; } }
  .indx-banner .slick-txt h2 + div {
    line-height: 1.33;
    color: black;
    font-weight: 400; }
    @media (max-width: 575.99px) {
      .indx-banner .slick-txt h2 + div {
        font-size: 1rem; } }
    @media (min-width: 576px) {
      .indx-banner .slick-txt h2 + div {
        font-size: 1.6875rem;
        letter-spacing: 1.9px; } }

.indx-banner .linebox {
  overflow: hidden;
  position: absolute;
  bottom: 30px;
  left: 60px;
  width: 25px;
  height: 180px; }
  @media (max-width: 767.99px) {
    .indx-banner .linebox {
      display: none; } }
  .indx-banner .linebox .scrline {
    width: 1px;
    height: 180px;
    background-color: black;
    position: absolute;
    bottom: 0;
    left: 0; }
  .indx-banner .linebox .mline {
    width: 2px;
    height: 60px;
    background-color: black;
    position: absolute;
    top: 0;
    left: 0;
    animation: lineplus 3s infinite ease-out; }
  .indx-banner .linebox span {
    color: black;
    font-weight: 400;
    font-size: 0.75rem;
    transform: rotate(90deg);
    transform-origin: left bottom;
    position: absolute;
    bottom: 31px;
    left: 3px; }

.indx .indx-brand {
  position: relative; }
  @media (max-width: 575.99px) {
    .indx .indx-brand {
      margin: 40px 0 60px; } }
  @media (min-width: 576px) {
    .indx .indx-brand {
      margin: 80px 0 120px; } }
  @media (max-width: 991.99px) {
    .indx .indx-brand .slick-list {
      padding-left: 10px; } }
  @media (min-width: 992px) {
    .indx .indx-brand .slick-list {
      padding-left: calc(50vw - 15px); } }
  @media (max-width: 991.99px) {
    .indx .indx-brand .slick-slide {
      padding: 10px; } }
  @media (min-width: 992px) {
    .indx .indx-brand .slick-slide {
      padding: 15px; } }
  .indx .indx-brand .btn-slick-prev, .indx .indx-brand .btn-slick-next {
    width: 9px;
    cursor: pointer;
    z-index: 10; }
  @media (max-width: 575.99px) {
    .indx .indx-brand .btn-slick-prev {
      position: absolute;
      top: 0;
      right: 80px; } }
  @media (min-width: 576px) {
    .indx .indx-brand .btn-slick-prev {
      position: absolute;
      top: 0;
      right: 100px; } }
  @media (max-width: 575.99px) {
    .indx .indx-brand .btn-slick-next {
      position: absolute;
      top: 0;
      right: 20px; } }
  @media (min-width: 576px) {
    .indx .indx-brand .btn-slick-next {
      position: absolute;
      top: 0;
      right: 60px; } }
  .indx .indx-brand.nbtn .btn_separate {
    display: none; }
  @media (min-width: 1500px) {
    .indx .indx-brand.nbtn_desk .btn_separate, .indx .indx-brand.nbtn_desk .btn-slick-prev, .indx .indx-brand.nbtn_desk .btn-slick-next {
      display: none; } }
  .indx .indx-brand .btn_separate {
    width: 1px;
    height: 12px;
    background-color: #a0a0a0; }
    @media (max-width: 575.99px) {
      .indx .indx-brand .btn_separate {
        position: absolute;
        top: 6px;
        right: 54px; } }
    @media (min-width: 576px) {
      .indx .indx-brand .btn_separate {
        position: absolute;
        top: 6px;
        right: 84px; } }
  @media (max-width: 575.99px) {
    .indx .indx-brand .slider-brand {
      padding-top: 30px; } }
  @media (min-width: 576px) {
    .indx .indx-brand .slider-brand {
      padding-top: 40px; } }
  .indx .indx-brand .hoverbox {
    display: block;
    overflow: hidden; }
    @media (max-width: 575.99px) {
      .indx .indx-brand .hoverbox {
        width: 220px; } }
    @media (min-width: 576px) {
      .indx .indx-brand .hoverbox {
        width: 350px; }
        .indx .indx-brand .hoverbox:hover .bgcover {
          transform: scale(1);
          opacity: 1; } }
  .indx .indx-brand .mdeafault {
    width: 50vw;
    height: 1px;
    background-color: yellowgreen; }
  .indx .indx-brand .bgcover {
    padding-bottom: 48%;
    opacity: 1; }
    .indx .indx-brand .bgcover .bgcover {
      opacity: 0;
      transition: opacity 0.6s; }
  .indx .indx-brand h4 {
    line-height: 1.5;
    color: black;
    font-weight: 400;
    font-size: 1.125rem;
    margin: 20px 0 16px; }
    .indx .indx-brand h4 a {
      display: inline;
      color: black; }

.indx .indx-about .slogan + div {
  line-height: 1.5;
  letter-spacing: 1.1px;
  color: black;
  font-weight: 400;
  font-family: 'Noto Sans JP', sans-serif; }
  @media (max-width: 767.99px) {
    .indx .indx-about .slogan + div {
      font-size: 0.9375rem;
      margin: 18px 0 24px; } }
  @media (min-width: 768px) {
    .indx .indx-about .slogan + div {
      font-size: 1.375rem;
      margin: 18px 0 48px; } }

@media (min-width: 992px) {
  .indx .indx-about .mtxt {
    max-width: 420px; } }

@media (max-width: 767.99px) {
  .indx .indx-about .bgtxt {
    position: absolute;
    top: -0.2em;
    right: -0.2em; } }

@media (min-width: 768px) {
  .indx .indx-about .bgtxt {
    position: absolute;
    bottom: -0.55em;
    left: -1.5em; } }

@media (min-width: 768px) {
  .indx .join-con {
    margin: 0 -8px; } }

@media (max-width: 767.99px) {
  .indx .join-con .join {
    margin: 100px 0; }
    .indx .join-con .join .slogan {
      margin-bottom: 10px; } }

@media (min-width: 768px) {
  .indx .join-con .join {
    margin: 200px 0; }
    .indx .join-con .join .slogan {
      margin-bottom: 20px; } }

@media (min-width: 992px) {
  .indx .join-con .join .textbox {
    padding-left: 35px; } }

@media (max-width: 767.99px) {
  .indx .join-con .join .bgtxt {
    position: absolute;
    top: -1.2em;
    left: -0.6em; } }

@media (min-width: 768px) {
  .indx .join-con .join .bgtxt {
    position: absolute;
    top: -0.7em;
    right: -1.6em; } }

@media (min-width: 768px) {
  .indx .join-con .cont .imgbox {
    order: 2; }
  .indx .join-con .cont .textbox {
    order: 1; }
  .indx .join-con .cont .textbox.revealOnScroll {
    transition-delay: 0.3s; } }

@media (max-width: 767.99px) {
  .indx .join-con .cont .bgtxt {
    position: absolute;
    top: -1.2em;
    left: 0; } }

@media (min-width: 768px) {
  .indx .join-con .cont .bgtxt {
    position: absolute;
    top: -0.7em;
    left: -1.5em; } }

.indx .join-con .hoverbox {
  position: relative; }
  @media (min-width: 992px) {
    .indx .join-con .hoverbox {
      display: flex; } }

@media (min-width: 992px) {
  .indx .join-con .imgbox, .indx .join-con .textbox {
    flex: 0 0 50%;
    width: 50%;
    padding: 0 8px; } }

.indx .join-con .imgbox a {
  display: block;
  overflow: hidden; }

@media (max-width: 1199.99px) {
  .indx .join-con .imgbox {
    width: 100%;
    margin-bottom: 16px; } }

.indx .join-con .bgcover {
  padding-bottom: 50%; }

.indx .join-con .textbox .mpsrel {
  height: 100%; }

@media (max-width: 991.99px) {
  .indx .join-con .btn_line {
    margin-top: 36px;
    display: block; } }

@media (min-width: 992px) {
  .indx .join-con .btn_line {
    position: absolute;
    bottom: 0;
    left: 0; } }

@keyframes lineplus {
  0% {
    transform: translateY(-60px); }
  100% {
    transform: translateY(180px); } }

@keyframes pulse {
  0% {
    transform: scale(0.5);
    opacity: 0; }
  5% {
    transform: scale(1);
    opacity: .5; }
  50% {
    opacity: 0.8; }
  100% {
    transform: scale(2.5);
    opacity: 0; } }

.editor_Box {
  background-color: white;
  box-shadow: 0px 0px 15px -3px #b3b3b3; }
  @media (max-width: 575.99px) {
    .editor_Box {
      padding: 20px 24px; } }
  @media (min-width: 576px) {
    .editor_Box {
      padding: 45px 32px; } }
  @media (max-width: 575.99px) {
    .editor_Box.mb50 {
      margin-bottom: 40px; } }
  @media (min-width: 576px) {
    .editor_Box.mb50 {
      margin-bottom: 50px; } }

.recruit .btn_box {
  letter-spacing: 1.2px;
  font-weight: 700; }
  @media (max-width: 575.99px) {
    .recruit .btn_box {
      font-size: 0.875rem;
      width: 130px;
      height: 40px;
      line-height: 39px; } }
  @media (min-width: 576px) {
    .recruit .btn_box {
      font-size: 1.5rem;
      width: 160px;
      height: 48px;
      line-height: 47px; } }

.brand .title-share h1,
.brand .mlist .mtitle {
  color: black;
  font-weight: 700;
  display: inline;
  line-height: 1.8;
  border-bottom: 2px solid black; }
  @media (max-width: 575.99px) {
    .brand .title-share h1,
    .brand .mlist .mtitle {
      font-size: 1rem; } }
  @media (min-width: 576px) {
    .brand .title-share h1,
    .brand .mlist .mtitle {
      font-size: 1.5rem; } }

.brand .title-share {
  position: relative; }
  @media (max-width: 575.99px) {
    .brand .title-share {
      margin-bottom: 20px;
      padding-right: 75px; } }
  @media (min-width: 576px) {
    .brand .title-share {
      margin-bottom: 48px;
      padding-right: 95px; } }
  .brand .title-share .share {
    position: absolute;
    top: 0.5em;
    right: 0; }
    .brand .title-share .share a {
      display: inline-block;
      opacity: 1;
      margin-left: 8px; }
      @media (max-width: 575.99px) {
        .brand .title-share .share a {
          width: 25px; } }
      @media (min-width: 576px) {
        .brand .title-share .share a {
          width: 35px; } }
    .brand .title-share .share a.hide {
      display: none; }
    @media (min-width: 768px) {
      .brand .title-share .share a:hover {
        opacity: 0.5; } }

@media (min-width: 768px) {
  .brand .mlist {
    margin: 0 -8px; } }

@media (max-width: 767.99px) {
  .brand .mlist > div {
    margin-bottom: 70px; } }

@media (min-width: 768px) {
  .brand .mlist > div {
    margin-bottom: 90px;
    display: flex; }
    .brand .mlist > div:nth-child(odd) .textbox {
      padding-left: 35px; }
    .brand .mlist > div:nth-child(even) .imgbox {
      order: 2; }
    .brand .mlist > div:nth-child(even) .textbox {
      order: 1; } }

@media (min-width: 768px) {
  .brand .mlist .imgbox, .brand .mlist .textbox {
    flex: 0 0 50%;
    width: 50%;
    padding: 0 8px; } }

@media (min-width: 768px) {
  .brand .mlist .imgbox.revealOnScroll {
    transition: opacity 1.5s ,transform 0.8s; } }

.brand .mlist .imgbox a {
  box-shadow: 0px 0px 15px -3px #b3b3b3;
  display: block;
  overflow: hidden; }

@media (max-width: 767.99px) {
  .brand .mlist .imgbox {
    width: 100%;
    margin-bottom: 12px; } }

.brand .mlist .imgbox .bgcover {
  padding-bottom: 50%;
  background-image: url(../images/brand_default.png); }

@media (min-width: 768px) {
  .brand .mlist .textbox.revealOnScroll {
    transition-delay: 0.3s; } }

.brand .mlist .textbox .mpsrel {
  height: 100%;
  padding-top: 1px; }

.brand .mlist .textbox .title-mt {
  overflow: hidden; }
  @media (max-width: 575.99px) {
    .brand .mlist .textbox .title-mt {
      height: 30px; } }
  @media (min-width: 768px) {
    .brand .mlist .textbox .title-mt {
      margin-top: -0.9em;
      height: 44px; } }

.brand .mlist .textbox .mdes {
  overflow: hidden;
  max-height: 4.5em; }
  @media (max-width: 767.99px) {
    .brand .mlist .textbox .mdes {
      margin-top: 16px;
      margin-bottom: 30px; } }
  @media (min-width: 768px) {
    .brand .mlist .textbox .mdes {
      margin-top: 36px;
      max-width: 380px; } }

@media (min-width: 768px) {
  .brand .mlist .textbox .btn_line {
    position: absolute;
    bottom: -4px;
    left: 0; } }

.about .bgbtm {
  max-width: 1200px;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  background: no-repeat url(../images/a_visionbg.png) right calc(100% - 40px); }
  @media (max-width: 991.99px) {
    .about .bgbtm {
      background-size: 600px;
      background-position: 70% 98%; } }

.about .mtitle {
  color: black;
  font-weight: 700;
  line-height: 1; }
  @media (max-width: 575.99px) {
    .about .mtitle {
      font-size: 1rem;
      margin-bottom: 16px; } }
  @media (min-width: 576px) {
    .about .mtitle {
      font-size: 1.5rem;
      margin-bottom: 45px; } }

@media (max-width: 575.99px) {
  .about .mtxt {
    line-height: 1.5;
    font-size: 0.875rem; } }

@media (min-width: 576px) {
  .about .mtxt {
    line-height: 1.87; } }

.about .slogan {
  line-height: 1.4;
  letter-spacing: 1.6px;
  color: black;
  font-weight: 400;
  position: relative; }
  @media (max-width: 575.99px) {
    .about .slogan {
      font-size: 1.125rem;
      margin-bottom: 10px; } }
  @media (min-width: 576px) {
    .about .slogan {
      font-size: 2rem;
      margin-bottom: 30px; } }

@media (max-width: 767.99px) {
  .about .sm-mb {
    margin-bottom: 40px; } }

@media (min-width: 768px) and (max-width: 991.99px) {
  .about .sm-mb {
    margin-bottom: 70px; } }

.about .policybox {
  position: relative; }
  @media (max-width: 767.99px) {
    .about .policybox {
      margin: 50px 0;
      padding: 50px 0;
      background-color: #ebebeb; } }
  @media (min-width: 768px) {
    .about .policybox {
      margin: 75px 0 60px;
      padding: 64px 0 40px;
      background: -webkit-linear-gradient(left, #ebebeb, #fff);
      background: -moz-linear-gradient(left, #ebebeb, #fff);
      background: -o-linear-gradient(left, #ebebeb, #fff);
      background: linear-gradient(to left, #ebebeb, #fff); } }
  .about .policybox:after {
    content: ' ';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0; }
    @media (max-width: 991.99px) {
      .about .policybox:after {
        background: no-repeat url(../images/a_linebg.png) 100% -23%/65%; } }
    @media (min-width: 768px) and (max-width: 991.99px) {
      .about .policybox:after {
        background-position: top right;
        background-size: contain; } }
    @media (min-width: 992px) {
      .about .policybox:after {
        background: no-repeat url(../images/a_linebg.png) center right/360px; } }
  .about .policybox .col-md-10 {
    position: relative;
    z-index: 10; }
    @media (min-width: 768px) {
      .about .policybox .col-md-10 {
        display: flex;
        flex-wrap: wrap;
        align-items: center; } }
    @media (max-width: 991.99px) {
      .about .policybox .col-md-10 {
        justify-content: center; } }
    @media (min-width: 992px) {
      .about .policybox .col-md-10 {
        justify-content: flex-start; } }
  .about .policybox .flex-center {
    position: relative; }
    @media (min-width: 768px) {
      .about .policybox .flex-center {
        height: 250px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        text-align: center; } }
  @media (max-width: 767.99px) {
    .about .policybox .titlebox {
      width: 120px;
      margin: 0 auto 50px;
      text-align: center;
      border-top: 4px double #a0a0a0;
      border-bottom: 4px double #a0a0a0;
      padding: 35px 0; } }
  @media (min-width: 768px) {
    .about .policybox .titlebox {
      border-top: 5px double #a0a0a0;
      border-bottom: 5px double #a0a0a0; } }
  @media (min-width: 768px) and (max-width: 991.99px) {
    .about .policybox .titlebox {
      width: 185px;
      flex: 0 0 185px;
      margin-bottom: 120px; }
      .about .policybox .titlebox + .mbk {
        flex: 0 0 100%; } }
  @media (min-width: 992px) {
    .about .policybox .titlebox {
      width: 20%;
      flex: 0 0 20%;
      margin-right: 50px; }
      .about .policybox .titlebox + .mbk {
        display: none; } }
  .about .policybox .titlebox .fs32 {
    line-height: 1;
    color: black;
    font-weight: 700; }
    @media (max-width: 575.99px) {
      .about .policybox .titlebox .fs32 {
        font-size: 1.25rem; } }
    @media (min-width: 576px) {
      .about .policybox .titlebox .fs32 {
        font-size: 2rem; } }
  .about .policybox .pol {
    box-shadow: 3.1px 3.9px 4.6px 0.5px rgba(0, 0, 0, 0.07);
    background-color: #ffffff;
    color: black;
    font-weight: 700;
    line-height: 1; }
    @media (min-width: 768px) {
      .about .policybox .pol img {
        display: block;
        margin: 0 auto 16px; } }
    @media (max-width: 767.99px) {
      .about .policybox .pol {
        width: 90%;
        padding: 36px 0;
        font-size: 1rem; }
        .about .policybox .pol > div:nth-child(1) {
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center; }
        .about .policybox .pol span {
          flex: 0 0 50%;
          width: 50%;
          text-align: center; }
        .about .policybox .pol.mrl {
          margin: 70px 0; } }
    @media (min-width: 768px) {
      .about .policybox .pol {
        font-size: 1.25rem; } }
    @media (min-width: 768px) and (max-width: 991.99px) {
      .about .policybox .pol {
        width: 30%;
        flex: 0 0 30%; }
        .about .policybox .pol.mrl {
          margin: 0 5%; } }
    @media (min-width: 992px) {
      .about .policybox .pol {
        width: 20%;
        flex: 0 0 20%; }
        .about .policybox .pol.mrl {
          margin: 0 50px; } }
  .about .policybox .num {
    font-family: "Nunito Sans", "Microsoft JhengHei", "微軟正黑體", sans-serif;
    line-height: 1;
    font-size: 6.25rem;
    color: #bae4dc;
    font-weight: 400;
    position: absolute;
    top: -0.5em;
    right: -0.5em;
    z-index: 10; }
    @media (max-width: 575.99px) {
      .about .policybox .num {
        letter-spacing: -0.0em;
        right: -50px; } }

.about .awar .slogan:before {
  content: "“ ";
  position: absolute;
  top: -3px;
  left: -0.8em; }

.about .awar .mh {
  width: 100%; }
  @media (max-width: 575.99px) {
    .about .awar .mh {
      height: 30px; } }
  @media (min-width: 576px) {
    .about .awar .mh {
      height: 60px; } }

@media (max-width: 575.99px) {
  .about .awar .pd45 {
    padding-left: 35px; } }

@media (max-width: 575.99px) {
  .about .vision {
    padding-bottom: 220px;
    margin-top: 60px; } }

@media (min-width: 576px) {
  .about .vision {
    padding-bottom: 120px;
    margin-top: 120px; } }

@media (min-width: 768px) and (max-width: 991.99px) {
  .about .vision {
    padding-bottom: 320px; } }

@media (min-width: 768px) {
  .about .revealOnScroll:nth-child(2) {
    transition-delay: 0.2s; }
  .about .revealOnScroll:nth-child(3) {
    transition-delay: 0.4s; }
  .about .revealOnScroll:nth-child(4) {
    transition-delay: 0.6s; }
  .about .vision .revealOnScroll {
    transition-delay: 0.2s; } }
